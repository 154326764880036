
export const userLogin = currentUser => ({
    type: "USER_LOGIN",
    currentUser
})

export const setUserRole = role => ({
    type: "SET_USER_ROLE",
    role
})

export const clearUserRole = () => ({
    type: "CLEAR_USER_ROLE"
})

export const userLogout = currentUser => ({
    type: "USER_LOGOUT",
    currentUser
})

export const setNotifications = notifications => ({
    type: 'SET_NOTIFICATIONS',
    notifications
})
