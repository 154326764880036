import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import MaterialTable from 'material-table'


import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const styles = theme => ({
    tableHeader: {
        color: 'white',
        backgroundColor:'#2196f3'
    },
    tableHeaderfont: {
        color:'white'
    }
})

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)

  }


class TableView extends Component{
    render(){
        const {rows, columns} = this.props
        const {classes} = this.props

        return(
            <Paper>
            <Table>
                <TableHead 
                className = {classes.tableHeader}
                onCellClick= {this.props.onCellClick}
                >
                    <TableRow>
                        {columns ?
                        columns.map((col, i) => {
                            return(
                                <TableCell key={i} className={classes.tableHeaderfont}>{col.label}</TableCell>
                            )
                       })
                    : null 
                    }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows ?
                    rows.map((row, i) => {
                        return( 
                           <TableRow key={i}>
                            {columns.map((col, colIndex) => {
                            return(
                                <TableCell key = {colIndex}>
                                    {row[col.name]}   
                                </TableCell>
                            )
                        })}
                        </TableRow>
                        )
                    })    
                : null
                }
                </TableBody>
            </Table>
        </Paper>
        )
    }
}

export default (withStyles(styles)(TableView))


class TableWithActions extends Component {
    render() {
        return (
            <MaterialTable
            options={{
                toolbar:false,
                actionsColumnIndex: -1
              }}
            icons={tableIcons}
            disabled
                columns = {this.props.columns}
                data={this.props.data}
                actions={this.props.actions}
                />
        )
    }
}

export {TableWithActions}