import {API} from '../service/RESTService'
import firebase from '../FirebaseSetup'


const ORDERS_ENDPOINT = 'api/v1/orders'

export const fetchOrders = (params,idToken) => {
    return API.GET(ORDERS_ENDPOINT,idToken,params)
}

export const setAsPaid = (params, idToken) => {
    return API.POST_WITH_PARAMS(ORDERS_ENDPOINT+"/"+params.orderId,idToken, params)
}