
export class Category {
    constructor(categoryId,categoryName,description,createdBy,updatedBy,categoryArabicName,images){
        this.categoryId = categoryId
        this.categoryName = categoryName
        this.description = description
        this.createdBy = createdBy
        this.updatedBy = updatedBy
        this.categoryArabicName = categoryArabicName
        this.images = images
    }
}
