import _ from 'lodash'
import { CardActions } from '@material-ui/core'

const CategoriesReducer = (state, action) => {
    switch (action.type){
        case 'SET_CATEGORIES_WITH_ALL_SUBCATEGORIES':
            return {
                ...state,
                categoriesWithAllSubCategories:action.categoriesWithAllSubCategories
            }
        case 'SET_ITEMS':
            return {
                ...state,
                selectedItems:action.itemsList
                
            }
        case 'SET_ITEMS_FILTER':
            return{
                ...state,
                itemsFilter:action.filter
            }
        case 'SELECT_CATEGORY':
            let index = _.findIndex(state.categoriesWithAllSubCategories, {'categoryId':action.category})
            console.log(state.categoriesWithAllSubCategories[index].subCategoryList)
            return{
                ...state,
                selectedSubCategory:state.categoriesWithAllSubCategories[index].subCategoryList,
                itemsFilter:{
                    ...state.itemsFilter,
                    category:{
                        value:action.category
                    }
                }
            }
        case 'CLEAR_SELECTED_CATEGORY':
            return {
                ...state,
                selectedSubCategory:[],
                itemsFilter:{
                    ...state.itemsFilter,
                    category:{
                        value:''
                    }
                }
            }

        case 'SELECT_SUBCATEGORY':
            return{
                ...state,
                itemsFilter:{
                    ...state.itemsFilter,
                    subCategory:{
                        value:action.subCategory
                    }
                }
        }
        case 'CLEAR_SELECTED_SUBCATEGORY':
            return{
                ...state,
                itemsFilter:{
                    ...state.itemsFilter,
                    subCategory:{
                        value:''
                    }
                }
            }
        case 'SET_CATEGORY_FILTER':
            return{
                ...state,
                categoryFilter:action.filter
            }
        default:
            return{
                state
            }
    }
    return state;
}

export default CategoriesReducer;