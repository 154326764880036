import React, {Component} from 'react'
import {withSnackbar } from 'notistack'

import LoadingScreen from '../../common/LoadingScreen'
import UpdateItem from './Update'
import AddItem from './Add'
import DeleteItem from './Delete'

//material-ui components
import Divider from '@material-ui/core/Divider'
import {TableWithActions} from '../../common/TableView'
import {FilterField,FilterDropDownWithLabel} from '../../common/FilterField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'

//styling
import {withStyles} from '@material-ui/core/styles'

//import context
import {CategoriesContext} from '../../../context/categories/Context'
import {setItems,setCategoriesWithAllSubCategories,setItemsFilter,selectCategory} from '../../../context/categories/Actions'


//items repository
import {fetchCategorieswithAllSubCategories} from '../../../repository/CategoriesRepository'
import {fetchItems} from '../../../repository/ItemsRepository'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

const columns = [
        {title : 'Item Name',field:'itemName'},
        {title : 'Description', field:'description'},
        {title : 'Unit Price', field:'unitPrice'},
        {title : 'Unit Type', field:'unitType'}
]

const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}

const newStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      selectOptions:{
          color:'green',
          backgroundColor:'yellow'
      }
})

class ManageItems extends Component{

        constructor(props) {
            super(props)

            this.state = {
                    item:{
                        data:'',
                        update:false,
                        delete:false,
                        add:false,
                    },
                    category:{
                        value:'',
                        index:-1
                    },
                    filter:{
                        item:{
                            value:''
                        },
                        category:{
                            value:''
                        },
                        subCategory:{
                            value:''
                        }
                    },
            
                    loading:{
                        status:false,
                        message:''
                    },
                    categoriesWithAlSubCategories:[]
                    
            }
        }
    static contextType = CategoriesContext

    //fetch categories with all sub categories
    getCategoriesWithAllSubCategories =async (state) => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting categories details'}})
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchCategorieswithAllSubCategories({},idToken).then((data) => {
                    if(data!=null){
                        dispatch(setCategoriesWithAllSubCategories(data))
                        this.setState({loading:{status:false,message:''}})
                    }
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Import category failed"+error,{variant:'error'}) //TODO change couldn't
            this.setState({loading:{status:false,message:''}})
        }finally{
           // this.setState({loading:{status:false,message:''}})
        }
    }
    
    //fetch items
    getItems = (state) => {
        const {dispatch } = this.context
        const params = this.setParams(state)
        this.setState({loading:{status:true,message:'Getting item details'}})
        try{
            FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchItems(params,idToken).then((data) => {
                   dispatch(setItems(data))
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Importing items failed"+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
        
    }

    setParams = (state) => {
        const {dispatch } = this.context
        const params = {}
        if(this.state.filter.subCategory.value!==''){
            params['subCategoryId'] = this.state.filter.subCategory.value
        }
        dispatch(setItemsFilter(state.filter))
        return params
    }

    selectCategory = (e) => {
        const {dispatch } = this.context
        this.setState({filter:{category:{value:e.target.value},subCategory:{value:''},item:{value:''}}})
        dispatch(selectCategory(e.target.value))
    }

    selectSubCategory = (e) => {
        this.setState({filter:{...this.state.filter,subCategory:{value:e.target.value}}})
    }

    handleInput =   (e)  =>  {
        this.setState({...this.state, filter:{...this.state.filter, item:{value:e.target.value}}})
    }

    saveFilter = () => {
        const {dispatch} = this.context
        dispatch(setItemsFilter(this.state.filter))
    }

    setFilter = () => {
        const {categories} = this.context
        this.setState({filter:categories.itemsFilter})

    }

    componentDidMount(){
        const {categories} = this.context
        this.setFilter()
        if(categories.categoriesWithAllSubCategories.length===0){
            this.getCategoriesWithAllSubCategories()
        }
    }

    render(){
        const {classes} = this.props
        const {categories} = this.context
        console.log(categories.itemsList)
        return(
            <div  style={{position:'relative'}}>
                 <div style = {styles.headingContainer}>
                <h4> <span>Manage Items</span></h4>
                <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        style={{marginLeft:'auto'}}
                        startIcon={<AddIcon/>}
                        onClick ={(e) => {this.setState({...this.state, item:{...this.state.item, add:true}})}}
                    >
                        Add Item
                    </Button>
                </div>
                <br></br>
                <div  className ="d-flex flex-row">
                    <Card>
                    <div className="w-100 d-flex flex-row">
                        <div style={{width:'180px',marginTop:'16px',marginLeft:'10px',marginRight:'10px'}}>
                            <FilterDropDownWithLabel
                                label="Category"
                                optionLabelId = 'categoryName'
                                optionValueId = 'categoryId'
                                value = {this.state.filter.category.value}
                                options = {categories.categoriesWithAllSubCategories}
                                onChange={(e)=>this.selectCategory(e)}
                            />
                        </div> 
                        <div style={{width:'180px',marginTop:'16px',marginLeft:'10px',marginRight:'10px'}}>
                            <FilterDropDownWithLabel
                                label = "Sub Category"
                                disabled={!categories.itemsFilter.category.value?true:false}
                                optionLabelId = 'subCategoryName'
                                optionValueId = 'subCategoryId'
                                value={this.state.filter.subCategory.value}
                                options = {categories.selectedSubCategory}
                                onChange = {(e)=>this.selectSubCategory(e)}
               
                            />
                        </div>
                        <div style={{width:'180px',marginTop:'16px',marginRight:'15px'}}>
                                    <FilterField 
                                        disabled ={this.state.filter.subCategory.value===''?true:false}
                                        id = "item"
                                        label = "Item Name"
                                        value = {this.state.filter.item.value}
                                        onChange = { e => {this.handleInput(e)}}
                                    />
                        </div>
                         
                        <div style={{alignSelf:'center',marginTop:'18px',marginRight:'15px'}}>
                            <Button
                                disabled ={this.state.filter.subCategory.value===''?true:false}
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.saveFilter()
                                    this.getItems(this.state)
                                }}
                            >Filter
                            </Button>
                        </div> 
                    </div>
                    <div style={{textAlign:'left',paddingLeft:'10px',height:'25px'}}>
                        {this.state.filter.subCategory.value===''?<span style={{color:'red'}}>&#9888; Select category and sub category to retrieve items </span>:null}
                    </div>
                    </Card>
                    
                </div>
                <Divider style={styles.divider}/>
                <div style={{position:'relative'}}>
                {this.state.filter.item.value==''?
                    <TableWithActions
                    columns = {columns}
                    data = {categories.selectedItems}
                    actions = {[
                        {
                            icon: Edit,
                            tooltip: 'Update',
                            onClick: (e,rowData)=>{this.setState({item:{...this.item, data:rowData, update:true}})}
                        },
                        {
                            icon: DeleteOutline,
                            tooltip: 'Remove',
                            onClick:(e,rowData)=> {this.setState({item:{...this.item,data:rowData,delete:true}})}
                        }
                    ]}
                />  
                :
                    <TableWithActions
                        columns = {columns}
                        data = {categories.selectedItems.filter(item=>{return item.itemName===this.state.filter.item.value})}
                        actions = {[
                            {
                                icon: Edit,
                                tooltip: 'Update',
                                onClick: (e,rowData)=>{this.setState({item:{...this.item, data:rowData, update:true}})}
                            },
                            {
                                icon: DeleteOutline,
                                tooltip: 'Remove',
                                onClick:(e,rowData)=> {this.setState({item:{...this.item,data:rowData,delete:true}})}
                            }
                        ]}
                    />  
            }
            </div>
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            {this.state.item.update?
            <UpdateItem
                open={this.state.item.update}
                item={this.state.item.data}
                selectedCategory = {this.state.filter.category.value}
                selectedSubCategories = {categories.selectedSubCategory}
                categories = {categories.categoriesWithAllSubCategories}
                handleClose={(e)=>{
                    this.setState({item:{...this.state.item, update:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({item:{...this.state.item, update:false}})
                    this.getItems(this.state)
                }}
            />
            :null
            }
            {this.state.item.add?
            <AddItem
                open={this.state.item.add}
                categories = {categories.categoriesWithAllSubCategories}
                handleClose={(e)=>{
                    this.setState({item:{...this.state.item, add:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({item:{...this.state.item, add:false}})
                    this.getItems(this.state)
                }}
            />
            :null    
            }
            {this.state.item.delete?
            <DeleteItem
                open={this.state.item.delete}
                item={this.state.item.data}
                selectedCategory = {this.state.filter.category.value}
                selectedSubCategories = {categories.selectedSubCategory}
                categories = {categories.categoriesWithAllSubCategories}
                handleClose={(e)=>{
                    this.setState({item:{...this.state.item, delete:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({item:{...this.state.item, delete:false}})
                    this.getItems(this.state)
                }}
            />
            :null    
            }
            </div>
        )
    }
}

export default withSnackbar((withStyles(newStyles)(ManageItems)))

