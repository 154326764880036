import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import moment from 'moment-timezone'
import {timeZone} from '../../../service/env'

//material-ui components
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import {TableWithActions} from './../../common/TableView'
import {FilterField, FilterDropDownWithLabel} from './../../common/FilterField'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
//styling
import {withStyles} from '@material-ui/core/styles'

//Context
import {getProviders,setApprovedProvidersFilter} from '../../../context/users/Actions'
import {UserContext} from '../../../context/users/Context'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

//orderRepository
import {fetchProviders} from '../../../repository/UsersRepository'

import LoadingScreen from '../../common/LoadingScreen'
import MenuIcon from '@material-ui/icons/Menu';
import ProviderDetails from './ProviderDetails'


const columns = [
        {title : 'Provider Id', field:'providerId'},
        {title : 'Provider Name', field:'firstName',render:rowData=><div>{rowData.firstName} {rowData.lastName}</div>},
        {title : 'Category', field:'providerId'},
        {title : 'City', field:'city'},
        {title : 'District', field:'district'},
        {title : 'Total Completed Orders', field:'totalCompletedOrders'},
        {title : 'Total Cancelled Orders', field:'totalCancelledOrders'},
        {title : 'Total Paid Amount', field:'totalPaidAmount'},
]
const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}



class ApprovedProviders extends Component{

        constructor(props) {
            super(props)

            this.state = {
                    status:'APPROVED',
                    provider:'',
                    category:'',
                    city:'',
                    district:'',
                    approvedProviders:[],
                    selectedProvider:'',
                    providerDetails:false,
                    loading:{
                        status:false,
                        message:''
                    },
            }
        }
    static contextType = UserContext

    setParams = () => {
        const {users} = this.context
        let params = {}
        params['status'] = this.state.status
        if(this.state.category!=''){
            params['category'] = this.state.category
        }
        if(this.state.provider!=''){
            params['firstName'] = this.state.provider
        }
        if(this.state.city!=''){
            params['city'] = this.state.city
        }
        if(this.state.district!=''){
            params['district'] = this.state.district
        }
        let arr = users.providers.filter(provider => {
            for (const [key, value] of Object.entries(params)) {
                if(provider[key]!=value){
                    return false
                }
              }
              return true
        })

        this.setState({approvedProviders:arr})
    }

    saveFilter = () => {
        const {dispatch} = this.context

        let filter = {}
        filter['status'] = this.state.status
        filter['provider'] = this.state.provider
        filter['city'] = this.state.city
        filter['district'] = this.state.district
        filter['category'] = this.state.category
        dispatch(setApprovedProvidersFilter(filter))
    }

    setFilter = () => {
        const {users} = this.context
        let filter = users.approvedProvidersFilter
        this.setState({provider:filter.provider})
        this.setState({city:filter.city})
        this.setState({district:filter.district})
        this.setState({status:filter.status})
        this.setState({category:filter.category})
    }

    getProviders = async () => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting Providers'}})
        let params = {}
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchProviders(params,idToken).then((data) => {
                        dispatch(getProviders(data))
                        this.setParams()
                        this.setState({loading:{status:false,message:''}})
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Import providers failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Import providers failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
    }

    componentDidMount = () => {
         const {users} = this.context
         if(users.providers.length==0){
             this.getProviders()
         }
         this.setFilter()
         this.setParams()
    }

    render(){
        const {classes} = this.props
        const {users} = this.context
        return(
            <div>
                <div  className ="d-flex flex-row">
                    <Card>
                    <div className="w-100 d-flex flex-row">
                            <div style={{width:'180px',marginTop:'14px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "Name"
                                    placeholder = "Name"
                                    value = {this.state.provider}
                                    onChange = { e => {this.setState( {provider:e.target.value})}}
                                    />
                            </div>
                            <div style={{width:'180px',marginTop:'14px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "City"
                                    placeholder = "City"
                                    value = {this.state.city}
                                    onChange = { e => {this.setState( {city :e.target.value})}}
                                    />
                               
                            </div>
                            <div style={{width:'180px',marginTop:'14px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "District"
                                    placeholder = "District"
                                    value = {this.state.district}
                                    onChange = { e => {this.setState( {district : e.target.value})}}
                                    />
                            </div>
                            <div style={{width:'180px',marginTop:'16px',marginLeft:'10px',marginRight:'10px'}}>
                            <FilterDropDownWithLabel
                                label="Category"
                                optionLabelId = 'categoryName'
                                optionValueId = 'categoryId'
                                value = {this.state.category}
                                options = {this.props.categories}
                                onChange={(e)=>this.setState({category : e.target.value})}
                            />
                        </div> 

                        <div style={{alignSelf:'center',margin:'10px',marginRight:'20px'}}>
                            <Button
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setParams()
                                    this.saveFilter()
                                }}
                            >Filter
                            </Button>
                        </div> 
                        <div style={{alignSelf:'center',margin:'10px',marginRight:'20px'}}>
                            <Button
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.getProviders()
                                }}
                            >Refresh
                            </Button>
                        </div>                
                    </div>
                    </Card>
                </div>
                <Divider style={styles.divider}/>
                <div>

                <TableWithActions
                    columns = {columns}
                    data = {this.state.approvedProviders}
                    actions = {[
                        {
                            icon: MenuIcon,
                            tooltip: 'Approve',
                            onClick:(e,rowData)=> {
                                this.setState({selectedProvider:rowData})
                                this.setState({providerDetails:true})
                                
                            }
                        },
                    ]}
                />


            </div>
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='300px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            {this.state.providerDetails==true?
                <ProviderDetails
                    open={this.state.providerDetails}
                    provider = {this.state.selectedProvider}
                    categories = {this.props.categories}
                    pendingProvider = {false}
                    handleClose={(e)=>{
                        this.setState({providerDetails:false})
                    }}
                    handleCloseWithRefresh={(e)=>{
                        this.setState({providerDetails:false})
                        this.getProviders()
                }}
                />    
                :null
            }
            </div>
        )
    }

}

export default withSnackbar((ApprovedProviders))