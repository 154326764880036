
const UserReducer = (state, action) => {
    switch (action.type){
        case 'GET_PROVIDERS':
            return {
                ...state,
                providers:action.providers
            }
        case 'GET_CUSTOMERS':
            return{
                ...state,
                customers:action.customers
            }
        case 'SET_PENDING_PROVIDERS_FILTER':
            return {
                ...state,
                pendingProvidersFilter : action.filter
            }
        case 'SET_APPROVED_PROVIDERS_FILTER':
            return {
                ...state,
                approvedProvidersFilter : action.filter
            }
        case 'SET_CUSTOMERS_FILTER':
            return{
                ...state,
                customerFilter : action.filter
            }
        default:
            return(
                state
            )
    }
}

export default UserReducer;