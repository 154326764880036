import React, {createContext, useReducer} from 'react'
import CategoriesReducer from './Reducers'

export const CategoriesContext = createContext();

const CategoriesContextProvider = (props) => {
    const [categories, dispatch] = useReducer(CategoriesReducer, {
            categoriesWithAllSubCategories:[],
            selectedSubCategory:[],
            selectedItems:[],
            itemsFilter:{
                item:{
                    value:''
                },
                category:{
                    value:'',
                },
                subCategory:{
                    value:'',
                }
        
            },
            categoryFilter:{
                categoryName:''
            }

    })


    return (
        <CategoriesContext.Provider value={{categories, dispatch}}>
           {props.children}
        </CategoriesContext.Provider>
    );
}

export default CategoriesContextProvider;
