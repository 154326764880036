import React, {Component} from 'react'



//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import InfoIcon from '@material-ui/icons/Info'


class ConfirmationBox extends Component {

    constructor(props){
        super(props)
        this.state={
            item:this.props.item,
            selectedCategory:this.props.selectedCategory,
            selectedSubCategories:[],
            deletedImages:[],
            addedImages:[],
            loading:{
                status:false,
                message:''
            },
            fileInput:''
        }
        this.inputRef = React.createRef()
    }

    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'400px',position:'relative'}}
                    className="bg-light"
                    >
                        <Card style={{padding:'20px'}}> 
                            <div className="row d-flex flex-direction-row" style={{padding:'10px',height:'100px'}}>
                                <InfoIcon style={{ fontSize: 40}} color="primary"
                                />
                                <div style={{padding:'10px'}}>
                                    {this.props.message}
                                </div>
                            </div>
                            <div className="row d-flex flex-direction-row" style={{paddingRight:'10px'}}>
                                <Button
                                        variant="contained"
                                        startIcon={<CancelIcon/>}
                                        size = "small"
                                        onClick = {(e) => {
                                            this.props.handleClose()
                                        }}
                                        style={{marginLeft:'auto',marginRight:'10px'}}
                                        color="secondary"
                                    >Cancel
                                </Button>

                                <Button
                                        variant="contained"
                                        startIcon={<CheckCircleIcon/>}
                                        size = "small"
                                        onClick = {(e) => {
                                            this.props.handleCloseConfirmed()
                                        }}
                                        color="primary"
                                    >confirm
                                </Button>
                            </div>
                        </Card>
                       </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default ConfirmationBox