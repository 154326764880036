import React, {createContext, useReducer} from 'react'
import UserReducer from './Reducers'

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const [users, dispatch] = useReducer(UserReducer, {
            providers:[],
            customers:[],
            pendingProvidersFilter:{
                status:'PENDING',
                category:'',
                provider:'',
                city:'',
                district:''
            },
            approvedProvidersFilter:{
                status:'APPROVED',
                category:'',
                provider:'',
                city:'',
                district:''
            },
            customerFilter:{
                customer: '',
                city: '',
                district: '',
                amount: ''
            }

    })


    return (
        <UserContext.Provider value={{users, dispatch}}>
           {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;
