export const setCategoriesWithAllSubCategories = categoriesWithAllSubCategories => ({
    type:'SET_CATEGORIES_WITH_ALL_SUBCATEGORIES',
    categoriesWithAllSubCategories
})


export const setItems = itemsList => ({
    type: "SET_ITEMS",
    itemsList
})

export const setItemsFilter = filter => ({
    type: 'SET_ITEMS_FILTER',
    filter
})

export const selectCategory = category => ({
    type: 'SELECT_CATEGORY',
    category
})

export const clearSelectedCategory = () => ({
    type:'CLEAR_SELECTED_CATEGORY'
})

export const selectSubCategory = subCategory => ({
    type:'SELECT_SUBCATEGORY',
    subCategory
})

export const clearSelectedSubCategory = () => ({
    type:'CLEAR_SELECTED_SUBCATEGORY'
})

export const setCategoryFilter = (filter) => ({
    type:'SET_CATEGORY_FILTER',
    filter
})