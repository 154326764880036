import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import moment from 'moment-timezone'
import {timeZone} from '../../service/env'
import ConfirmationBox from '../common/ConfirmationBox'

//material-ui components
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import {TableWithActions} from './../common/TableView'
import {FilterField, FilterDropDown,FilterDatePicker} from './../common/FilterField'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'

//styling
import {withStyles} from '@material-ui/core/styles'

//Context
import {getServiceCharge} from '../../context/configurations/Actions'
import {ConfigurationsContext} from '../../context/configurations/Context'

//firebase
import FirebaseApp from '../../FirebaseSetup'

//orderRepository
import {fetchServiceCharge, setServiceCharge, createEditor} from '../../repository/ConfigurationsRepository'

import LoadingScreen from '../common/LoadingScreen'


const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}



class Settings extends Component{

        constructor(props) {
            super(props)

            this.state = {
                    serviceCharge:0,
                    username:'',
                    email:'',
                    password1:'',
                    password2:'',
                    loading:{
                        status:false,
                        message:''
                    },
                    confirm:false
                       
                    
            }
        }
    static contextType = ConfigurationsContext

    validateServiceCharge = () => {
        if(isNaN(this.state.serviceCharge)){
            this.props.enqueueSnackbar("Enter a valid service charge. Ex: 10.00",{variant:'warning'})
            return false
        }else{
            return true
        }
    }

    validateCreateEditor = () => {
        if(
            this.state.username ==''||
            this.state.email == ''||
            this.state.password1 ==''||
            this.state.password2 == ''
        ){
            this.props.enqueueSnackbar("All fields shoud be filled",{variant:'warning'})
            return false
        }else if(
            this.state.password1 != this.state.password2
        ){
            this.props.enqueueSnackbar("Entered passwords didnt match",{variant:'warning'})
            return false
        }else {
            return true
        }
    }

    getServiceCharge = async () => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting information'}})
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchServiceCharge(idToken).then((data) => {
                        dispatch(getServiceCharge(data))
                        this.setState({serviceCharge:data.serviceCharge})
                        this.setState({loading:{status:false,message:''}})
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Importing failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Importing failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
    }

    setServiceCharge = async () => {
        
        if(this.validateServiceCharge()){
            this.setState({loading:{status:true,message:'Saving information'}})
            try{

                let params = {
                    serviceCharge : this.state.serviceCharge
                }
                await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                    setServiceCharge(idToken, params).then((data) => {
                            this.setState({loading:{status:false,message:''}})
                            this.getServiceCharge()
                    }).catch((error)=>{
                        this.props.enqueueSnackbar("Update settings failed "+error,{variant:'error'})
                       this.setState({loading:{status:false,message:''}})
                    })
                  })
            }catch(error){
                this.props.enqueueSnackbar("Update settings failed "+error,{variant:'error'})
                this.setState({loading:{status:false,message:''}})
            }
        }
    }

    clearFields = () => {
        this.setState({
            username:'',
            email:'',
            password1:'',
            password2:''
        })
    }

    createEditor = async () => {
        let editor = {
            username : this.state.username,
            email : this.state.email,
            password : this.state.password1,
            role : "editor"
        }

        if(this.validateCreateEditor()){
            try{
                this.setState({loading:{status:true,message:'Creating new editor'}})
                await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                    createEditor(idToken, editor).then(async (data) => {
                            await FirebaseApp.auth().sendPasswordResetEmail(this.state.email).then((data)=>{
                                this.clearFields()
                                this.props.enqueueSnackbar("New editor created",{variant:'success'})
                                this.setState({loading:{status:false,message:''}})
                            }).catch((error)=>{
                                this.props.enqueueSnackbar("Create editor failed "+error,{variant:'error'})
                               this.setState({loading:{status:false,message:''}})
                            })

                    }).catch((error)=>{
                        this.props.enqueueSnackbar("Create editor failed "+error,{variant:'error'})
                       this.setState({loading:{status:false,message:''}})
                    })
                  })
            }catch(error){
                this.props.enqueueSnackbar("Create editor failed "+error,{variant:'error'})
                this.setState({loading:{status:false,message:''}})
            }
        }
    }

    componentDidMount = () => {
        this.getServiceCharge()
    }

    render(){
        const {classes} = this.props
        const {configurations} = this.context
        return(
            <div>
                 <div style = {styles.headingContainer}>
                <h4> <span>Settings</span></h4>
                </div>
                <br></br>
                <div  className ="">
                    <Card style={{height:'130px', width:'80%', minWidth: '700px'}}> 
                        <div>
                            <div className ="row" style={{padding:'10px 0px 0px 30px',width:'100%'}}>
                               
                                    <h6>Service Charge  : </h6>
                                
                            </div>
                            <div className ="row" style={{padding:'0px 0px 0px 80px',width:'100%'}}>
                               <div>
                               <TextField
                                       style={{marginTop:'5px',marginBottom:'10px'}}
                                       margin="normal"
                                       size = "small"
                                       value={this.state.serviceCharge}
                                       inputProps={{style: {fontSize: '16px',textAlign:'center'}}}
                                       onChange = {(e) => {this.setState({serviceCharge:e.target.value})}}
                               /> 
                               </div>
                           
                       </div>
                       <div className ="row" style={{padding:'0px 0px 0px 130px',width:'100%'}}>
                               <div>
                               <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{margin:'0px',width:'150px'}}
                                onClick ={(e) => {
                                    this.setServiceCharge()
                                }}
                            >
                                Change
                            </Button>
                               </div>
                           
                       </div>
                        </div>
                    </Card>
                    <br></br>
                    <Card style={{height:'400px', width:'80%', minWidth: '700px'}}> 
                        <div>
                            <div className ="row" style={{padding:'10px 0px 10px 30px',width:'100%'}}>
                               
                                    <h6>Editor Accounts  : </h6>
                            </div>
                            <div className ="row" style={{textAlign:'left'}}>
                            <div style={{padding:'0px 0px 0px 80px',width:'100%'}}>
                                    <TextField
                                            disabled={this.props.disabled}
                                            label="Username"
                                            style={{marginTop:'5px',marginBottom:'10px'}}
                                            margin="normal"
                                            size = "small"
                                            inputProps={{style: {fontSize: '16px'}}}
                                            onChange = {(e) => [this.setState({username:e.target.value})]}
                                            value={this.state.username}
                                    /> 
                                    </div>
                            </div>
                            <div className ="row" style={{textAlign:'left'}}>
                            <div style={{padding:'0px 0px 0px 80px',width:'100%'}}>
                                    <TextField
                                            disabled={this.props.disabled}
                                            label="Email"
                                            style={{marginTop:'5px',marginBottom:'10px'}}
                                            margin="normal"
                                            size = "small"
                                            inputProps={{style: {fontSize: '16px'}}}
                                            onChange = {(e) => [this.setState({email:e.target.value})]}
                                            value={this.state.email}
                                    /> 
                                    </div>
                            </div>
                            <div className ="row" style={{textAlign:'left'}}>
                            <div style={{padding:'0px 0px 0px 80px',width:'100%'}}>
                                    <TextField
                                            disabled={this.props.disabled}
                                            label="password"
                                            style={{marginTop:'5px',marginBottom:'10px'}}
                                            margin="normal"
                                            size = "small"
                                            type ="password"
                                            inputProps={{style: {fontSize: '16px'}}}
                                            onChange = {(e) => [this.setState({password1:e.target.value})]}
                                            value={this.state.password1}
                                    /> 
                                    </div>
                                    
                            </div>
                            <div className ="row" style={{textAlign:'left'}}>
                            <div style={{padding:'0px 0px 0px 80px',width:'100%'}}>
                                    <TextField
                                            disabled={this.props.disabled}
                                            label="password"
                                            style={{marginTop:'5px',marginBottom:'10px'}}
                                            margin="normal"
                                            size = "small"
                                            type ="password"
                                            inputProps={{style: {fontSize: '16px'}}}
                                            onChange = {(e) => [this.setState({password2:e.target.value})]}
                                            value={this.state.password2}
                                    /> 
                                    </div>
                                    
                            </div>
                            <div className ="row" style={{padding:'0px 0px 0px 130px',width:'100%'}}>
                               <div>
                               <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{margin:'0px',width:'150px'}}
                                onClick = {(e) => {this.createEditor()}}
                            >
                                Create
                            </Button>
                               </div>
                           
                       </div>
                        </div>
                    </Card>
                </div>
                <div>

            </div>
            {this.state.confirm?
                    <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.state.confirm}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.confirm}>
                    <div style={{backgroundColor:'white',width:'300px',position:'relative'}}
                    className="bg-light"
                    >
                        <Card style={{padding:'20px'}}> 
                            <div className="row d-flex flex-direction-row" style={{padding:'10px',height:'150px'}}>
                                <div style={{padding:'10px'}}>
                                    <h6>Set paid date</h6>
                                    <FilterDatePicker
                                    id="to"
                                    label=""
                                    value={this.state.selectedPayment.paymentDueDate}
                                    onChange={(date) => this.setState({selectedPayment:{ ...this.state.selectedPayment, paymentDueDate:date}})}
                                    />
                                </div>
                            </div>
                            <div className="row d-flex flex-direction-row" style={{paddingRight:'10px'}}>
                                <Button
                                        variant="contained"
                                        startIcon={<CancelIcon/>}
                                        size = "small"
                                        onClick = {(e) => {
                                            this.setState({confirm:false})
                                        }}
                                        style={{marginLeft:'auto',marginRight:'10px'}}
                                        color="secondary"
                                    >Cancel
                                </Button>

                                <Button
                                        variant="contained"
                                        startIcon={<CheckCircleIcon/>}
                                        size = "small"
                                        onClick = {(e) => {
                                            this.setState({confirm:false})
                                        }}
                                        color="primary"
                                    >confirm
                                </Button>
                            </div>
                        </Card>
                       </div>
                    </Fade>
                </Modal>
                    :null    
                    }
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='300px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            </div>
        )
    }
}

export default withSnackbar((Settings))