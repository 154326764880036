import FirebaseApp from "../FirebaseSetup"
import firebase from "firebase"
import {BroadcastChannel} from 'broadcast-channel'


const logoutChannel = new BroadcastChannel('logout channel');

export const signInUser = (email, password,checked) => {

    if(checked){
      FirebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  }else{
      FirebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
  }
    return FirebaseApp.auth().signInWithEmailAndPassword(email, password)
    
}

export const checkUser = () => {
    if (FirebaseApp.auth().currentUser) {
      return FirebaseApp.auth().currentUser
    } else {
      return null
    }
}


export const signOutUser =  () => {
    return (FirebaseApp.auth().signOut().then(function() {
        // logoutChannel.postMessage('logout channel')
      }).catch(function(error) {
        // An error happened.
      }))
}

// export const logoutAllTabsListner = () => {
//   console.log('listner is enabled')
//   logoutChannel.onmessage = (event) => {
//     logoutChannel.close()
//     window.location.reload(false);
//   }
// }