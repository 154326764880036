import {API} from '../service/RESTService'
import firebase from '../FirebaseSetup'
import UUIDGenerator from '../service/UUIDGenerator'

const CATEGORIES_WITH_ALL_SUBCATEGORIES_ENDPOINT = 'api/v1/categories/categoriesWithAllSubCategories'
const CATEGORIES_ENDPOINT = 'api/v1/categories'

var storage = firebase.storage()
var storageRef = storage.ref('categories')


export const fetchCategorieswithAllSubCategories = (params, idToken) => {
    return API.GET(CATEGORIES_WITH_ALL_SUBCATEGORIES_ENDPOINT,idToken, params)
}

export const addCategory = (category,idToken) => {
    let categories = []
    categories.push(category)
    let categoryJSON = JSON.stringify(categories)
    return API.POST(CATEGORIES_ENDPOINT,idToken,categoryJSON)
}

export const deleteCategory = (categoryId, idToken) => {
    return API.DELETE(CATEGORIES_ENDPOINT+'/'+categoryId,idToken,[])
}

export const addSubCategory = (categoryId,subCategory, idToken) => {
    let subCategories = []
    subCategories.push(subCategory)
    let subCategoriesJSON = JSON.stringify(subCategories)
    return API.POST(CATEGORIES_ENDPOINT+'/'+categoryId+'/subCategories',idToken,subCategoriesJSON)
}

export const deleteSubCategory = (categoryId, subCategoryId, idToken) => {
    return API.DELETE(CATEGORIES_ENDPOINT+'/'+categoryId+'/subCategories/'+subCategoryId,idToken,[])
}

export const uploadImages = async (images) => {
    try{ let uploadedImages = []
         for(let x=0; x<images.length;x++){
             let image = images[x]
             let imageId = UUIDGenerator().toString()
             let fileExtension = image.file.name.replace(/^.*\./, '');
             let imageRef = storageRef.child(imageId+'.'+fileExtension) 
             const res = await imageRef.put(image.file)
             const url = await res.ref.getDownloadURL()
             const imageData = {
                 id: imageId+'.'+fileExtension,
                 url:url
             }
             await uploadedImages.push(imageData)
         }
         return uploadedImages
     }catch(error){
         throw error
     }
 }

export const deleteImages =async (images) => {
    try{for(let x=0; x<images.length;x++){
        let image = images[x]
        let imageRef = storageRef.child(image.id)
        await imageRef.delete()
        }
    }catch(error){
        throw error
    }
}