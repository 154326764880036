import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'

//material-ui components
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import {TableWithActions} from '../../common/TableView'
import {FilterField, FilterDropDown,FilterDatePicker} from '../../common/FilterField'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import ImageIcon from '@material-ui/icons/Image'

//styling
import {withStyles} from '@material-ui/core/styles'

//Context
import {getSlides} from '../../../context/configurations/Actions'
import {ConfigurationsContext} from '../../../context/configurations/Context'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

//orderRepository
import {fetchSlides} from '../../../repository/ConfigurationsRepository'

import LoadingScreen from '../../common/LoadingScreen'
import Update from './Update'



const columns = [
        {title : 'Image Id', field:'imageId'},
        {title : 'Image Name', field:'imageName'},
        {title : 'Image Position', field:'imagePosition'},
        {title : 'Image', field:'image',render:rowData=><div><img style={{width:'250px',height:'80px',objectFit:'cover'}} src={rowData.url}></img></div>},
]
const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}



class ManageSlides extends Component{

        constructor(props) {
            super(props)

            this.state = {
                    actions:{
                        data:[],
                        update:false,
                        delete:false,
                        add:false
                    },
                    loading:{
                        status:false,
                        message:''
                    },
                    imageViewer:{
                        images:'',
                        status:false
                    }
            }
        }
    static contextType = ConfigurationsContext
    
    openImageViewer = (e, rowData) => {
        let arr = []
        let img = {src:rowData.url}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }


    getSlides = async () => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting Slides'}})

        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchSlides(idToken).then((data) => {
                        dispatch(getSlides(data))
                        this.setState({loading:{status:false,message:''}})
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Import slides failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Import slides failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
    }

    componentDidMount = () => {
        const {configurations} = this.context
        if(configurations.slides.length==0){
            this.getSlides()
        }
    }

    render(){
        const {classes} = this.props
        const {configurations} = this.context
        return(
            <div>
                 <div style = {styles.headingContainer}>
                <h4> <span>Manage Slides</span></h4>
                </div>
                <br></br>
                <Divider style={styles.divider}/>
                <div>

                <TableWithActions
                    columns = {columns}
                    data = {configurations.slides.slidesList}
                    actions = {[
                        {
                            icon: Edit,
                            tooltip: 'Update',
                            onClick: (e,rowData)=>{this.setState({actions:{...this.actions, data:rowData, update:true}})}
                          },
                          {
                            icon: ImageIcon,
                            tooltip: 'View Image',
                            onClick: (e,rowData)=>{this.openImageViewer(e, rowData)}
                          }
                    ]}
                />


            </div>
            {this.state.actions.update?
            <Update
                open={this.state.actions.update}
                data={configurations.slides}
                slide={this.state.actions.data}
                handleClose={(e)=>{
                    this.setState({actions:{...this.state.actions, update:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({actions:{...this.state.actons, update:false}})
                    this.getSlides()
                }}
            />
            :null
            }
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='300px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            {this.state.imageViewer.status?
                <ImageViewer
                imgs={ this.state.imageViewer.images }
                currImg={0}
                isOpen = {this.state.imageViewer.status}
                onClose={ this.closeImageViewer }
                />
                : null    
        }
            </div>
        )
    }
}

export default withSnackbar((ManageSlides))