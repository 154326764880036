import React, { Component } from 'react'
import { withSnackbar } from 'notistack'

import LoadingScreen from '../common/LoadingScreen'

//material-ui components
import Divider from '@material-ui/core/Divider'
import { TableWithActions } from '../common/TableView'
import { FilterField, FilterDropDownWithLabel } from '../common/FilterField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'

//styling
import { withStyles } from '@material-ui/core/styles'

//import context
import { UserContext } from '../../context/users/Context'
import {getCustomers, setCustomersFilter } from '../../context/users/Actions'


//items repository
import { fetchCustomers } from '../../repository/UsersRepository'

//firebase
import FirebaseApp from '../../FirebaseSetup'

const columns = [
    { title: 'Customer ID', field: 'userId' },
    { title: 'Customer Name', field: 'description', render: rowData => <div>{rowData.firstName + ' ' +rowData.lastName}<br></br>{rowData.customerId}</div> },
    { title: 'City', field: 'city' },
    { title: 'District', field: 'district' },
    { title: 'Total Orders', field: 'orderCount' },
    { title: 'Total Amount', field: 'totalOrderAmount' },
]

const styles = {
    headingContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop: '0',
    },
    divider: {
        marginTop: '10px',
        marginBottom: '10px'
    }
}

const newStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectOptions: {
        color: 'green',
        backgroundColor: 'yellow'
    }
})

class ManageCustomers extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            filter: {
                customer: '',
                city: '',
                district: '',
                amount: ''
            },
            loading: {
                status: false,
                message: ''
            },
            categoriesWithAlSubCategories: []

        }
    }
    static contextType = UserContext

    setParams = () => {
        const { users } = this.context
        let params = {}
        if (this.state.filter.customer != '') {
            params['customerName'] = this.state.filter.customer
        }
        if (this.state.filter.city != '') {
            params['city'] = this.state.filter.city
        }
        if (this.state.filter.district!= '') {
            params['district'] = this.state.filter.district
        }
        if (this.state.filter.amount != '') {
            params['totalOrderAmount'] = this.state.filter.amount
        }
        let arr = users.customers.filter(customer => {
            for (const [key, value] of Object.entries(params)) {
                if (customer[key] != value) {
                    return false
                }
            }
            return true
        })
        this.setState({ customers: arr })
    }

    //fetch customers
    getCustomers = async () => {
        const { dispatch } = this.context
        this.setState({ loading: { status: true, message: 'Getting customer details' } })
        try {
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchCustomers({}, idToken).then((data) => {
                    if (data != null) {
                        dispatch(getCustomers(data))
                        this.setParams()
                        this.setState({ loading: { status: false, message: '' } })
                    }
                })
            })
        } catch (error) {
            this.props.enqueueSnackbar("Import customers failed" + error, { variant: 'error' })
            this.setState({ loading: { status: false, message: '' } })
        } finally {
            // this.setState({loading:{status:false,message:''}})
        }
    }

    saveFilter = () => {
        const { dispatch } = this.context
        dispatch(setCustomersFilter(this.state.filter))
    }

    setFilter = () => {
        const { users } = this.context
        this.setState({ filter: users.customerFilter })

    }

    componentDidMount() {
        const { users } = this.context
        this.setFilter()
        this.setParams()
        if (users.customers.length === 0) {
            this.getCustomers()
        }
    }

    render() {
        const { classes } = this.props
        const { users } = this.context
        return (
            <div style={{ position: 'relative' }}>
                <div style={styles.headingContainer}>
                    <h4> <span>Manage Customers</span></h4>
                </div>
                <br></br>
                <div className="d-flex flex-row">
                    <Card>
                        <div className="w-100 d-flex flex-row">
                            <div style={{ width: '180px', marginTop: '16px', marginRight: '15px' }}>
                                <FilterField
                                    id="customer"
                                    label="Customer"
                                    value={this.state.filter.customer}
                                    onChange={e => { this.setState({filter:{...this.state.filter, customer:e.target.value}})}}
                                />
                            </div>
                            <div style={{ width: '180px', marginTop: '16px', marginRight: '15px' }}>
                                <FilterField
                                    id="city"
                                    label="City"
                                    value={this.state.filter.city}
                                    onChange={e => { this.setState({filter:{...this.state.filter, city:e.target.value}})}}
                                />
                            </div>
                            <div style={{ width: '180px', marginTop: '16px', marginRight: '15px' }}>
                                <FilterField
                                    id="district"
                                    label="District"
                                    value={this.state.filter.district}
                                    onChange={e => { this.setState({filter:{...this.state.filter, district:e.target.value}})}}
                                />
                            </div>
                            <div style={{ width: '180px', marginTop: '16px', marginRight: '15px' }}>
                                <FilterField
                                    id="amount"
                                    label="Amount"
                                    value={this.state.filter.amount}
                                    onChange={e => { this.setState({filter:{...this.state.filter, amount:e.target.value}})}}
                                />
                            </div>

                            <div style={{ alignSelf: 'center', marginTop: '18px', marginRight: '15px' }}>
                                <Button
                                    variant="contained"
                                    startIcon={<FilterListIcon />}
                                    size="small"
                                    onClick={(e) => {
                                        this.saveFilter()
                                        this.setParams()
                                    }}
                                >Filter
                            </Button>
                            </div>
                        </div>
                    </Card>

                </div>
                <Divider style={styles.divider} />
                <div style={{ position: 'relative' }}>
                    <TableWithActions
                        columns={columns}
                        data={this.state.customers}
                    />

                </div>
                {this.state.loading.status ?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY='200px'
                        message={this.state.loading.message}
                    />
                    : null
                }
            </div>
        )
    }
}

export default withSnackbar((withStyles(newStyles)(ManageCustomers)))

