
export class Item {
    constructor(
                itemId,
                itemNameArabic,
                itemDescriptionArabic,
                unitTypeArabic,
                itemName,
                searchTags,
                description,
                unitType,
                unitPrice,
                subCategoryId,
                images,
                createdAt,
                updatedAt){
        this.itemNameArabic= itemNameArabic
        this.itemDescriptionArabic = itemDescriptionArabic
        this.unitTypeArabic= unitTypeArabic
        this.itemId = itemId
        this.itemName = itemName
        this.searchTags = searchTags
        this.description = description
        this.unitType = unitType
        this.unitPrice = unitPrice
        this.subCategoryId = subCategoryId
        this.images = images
        this.createdAt = createdAt
        this.updatedAt = updatedAt
    }
}
