import React, {Component} from 'react'
import CountUp from 'react-countup'
import {withSnackbar } from 'notistack'

//repository
import {getData} from '../../repository/DashboardRepository'

//firebase
import FirebaseApp from '../../FirebaseSetup'

import LoadingScreen from '../common/LoadingScreen'

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
                data:{
                    newRequests:0,
                    unattendedRequests:0,
                    pendingOrders:0,
                    completedOrders:0,
                    activeProviders:0,
                    registeredCustomers:0,
                    registeredProviders:0,
                    cancelledOrders:0,
                },
                loading:{
                    status:false,
                    message:''
                },
                confirm:false
                   
                
        }
    }

    getData = async () => {
        this.setState({loading:{status:true,message:'Getting information'}})
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                getData(idToken).then((data) => {
                        this.setState({data:data})
                        this.setState({loading:{status:false,message:''}})
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Importing failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Importing failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
    }

    componentDidMount = () => {
        this.getData()
    }

    render(){
        return (
            <div>
                            <div className="container mt-2">
                <div className="row" style={{
                
                }}>
                <DashboardCount 
                    name = "New Requests"
                    count = {this.state.data.newRequests}
                        />
                 <DashboardCount 
                    name = "Un Attended Requests"
                    count = {this.state.data.unattendedRequests}
                        />
                <DashboardCount 
                    name = "Pending Orders"
                    count = {this.state.data.pendingOrders}
                        />
                <DashboardCount 
                    name = "Completed Orders"
                    count = {this.state.data.completedOrders}
                        />
                <DashboardCount 
                    name = "Currently Active Providers"
                    count = {this.state.data.activeProviders}
                        />
                <DashboardCount 
                    name = "Newly Registered Customers"
                    count = {this.state.data.registeredCustomers}
                        />
                <DashboardCount 
                    name = "Newly Registered Providers"
                    count = {this.state.data.registeredProviders}
                        />
                <DashboardCount 
                    name = "Cancelled Orders"
                    count = {this.state.data.cancelledOrders}
                        />                                                                                                 
            </div>
            </div>
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='300px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            </div>
        )
    }
}

export default withSnackbar(Dashboard)

class DashboardCount extends Component{
    render() {
        return (
         <div className="col-lg-3">
             <div className="card p-2 m-1">
                <CountUp
                 end = {this.props.count}
                 duration = {3}
                 style={{
                     color:'#0033cc',
                     fontWeight: 'bold',
                     fontSize: '3rem'
                    }}
                 />
                {this.props.name}
                <br></br>
                
            </div>
         </div>   
        )
    }
}

