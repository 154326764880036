import {API} from '../service/RESTService'


const PROVIDERS_ENDPOINT = 'api/v1/providers'
const CUSTOMERS_ENDPOINT = 'api/v1/users'

export const fetchProviders = (params, idToken) => {
    return API.GET(PROVIDERS_ENDPOINT, idToken, params)
}

export const approveProvider = (providerId,idToken) => {
    return API.POST(PROVIDERS_ENDPOINT+'/approve/'+providerId, idToken,{})
}

export const fetchCustomers = (params, idToken) => {
    return API.GET(CUSTOMERS_ENDPOINT, idToken, params)
}