import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import AuthContextProvider from './context/auth/Context'
import CategoriesContextProvider from './context/categories/Context'
import OrderContextProvider from './context/orders/Context'
import ConfigurationsContextProvider from './context/configurations/Context'
import UserContextProvider from './context/users/Context'
ReactDOM.render(
  
  <React.StrictMode>
    
    <BrowserRouter>
      <AuthContextProvider>
        <ConfigurationsContextProvider>
        <CategoriesContextProvider>
          <OrderContextProvider>
            <UserContextProvider>
              <App/>
          </UserContextProvider>
          </OrderContextProvider>
        </CategoriesContextProvider>
        </ConfigurationsContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
</React.StrictMode>

,
document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
