import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'

import Field from '../../common/Field'
import {deleteImages, deleteSubCategory} from '../../../repository/CategoriesRepository'
import _ from 'lodash'
import LoadingScreen from '../../common/LoadingScreen'
import { SubCategory } from '../../../models/SubCategory'
import ConfirmationBox from '../../common/ConfirmationBox'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'


//firebase
import FirebaseApp from '../../../FirebaseSetup'

class DeleteSubCategory extends Component {

    constructor(props){
        super(props)
        this.state={
            subCategory:this.props.subCategory,
            categoryId:this.props.categoryId,
            loading:{
                status:false,
                message:''
            },
            confirm:{
                status:false,
                message:''
            },
            imageViewer:{
                images:'',
                status:false
            }
        }
        this.inputRef = React.createRef()
    }

    openImageViewer = (e) => {
        let arr = []
        let img = {src:e.target.src}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }

    deleteSubCategory = async () => {
             try{
                if(this.state.subCategory.images.length!=0){
                    this.setState({loading:{status:true,message:'Deleting images'}})
                    await deleteImages(this.state.subCategory.images)
                }
 
                 this.setState({loading:{status:true,message:'Deleting subcategory'}})
                 await FirebaseApp.auth().currentUser.getIdToken(true).then(async (idToken) => {
                         await deleteSubCategory(this.state.categoryId,this.state.subCategory.subCategoryId,idToken)
                         this.props.enqueueSnackbar("Subcategory was deleted successfully",{variant:'success'})
                     })
                 this.props.handleCloseWithRefresh()
             }catch(error){
                 this.props.enqueueSnackbar("Delete subcategory was failed "+error,{variant:'error'})
             }finally{
                 this.setState({loading:{status:false,message:''}})
             } 
     }
    
    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'450px',position:'relative',paddingBottom:'20px'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Manage Sub Categories</h4>
                        <Card style={{padding:'10px'}}>
                        <div>
                                    <Field
                                        disabled={true}
                                        type='text'
                                        id='name'
                                        placeholder='Sub Category Name'
                                        size='small'
                                        value = {this.state.subCategory.subCategoryName}
                                        onChange = {(e)=>{this.setState({subCategory:{...this.state.subCategory,subCategoryName:e.target.value}})}}
                                        autoFocus={false}
                                        required={true}
                                    />
                                    <Field
                                        disabled={true}
                                        type='text'
                                        id='arabicName'
                                        placeholder='Arabic Name'
                                        value = {this.state.subCategory.subCategoryArabicName}
                                        onChange = {(e)=>{this.setState({subCategory:{...this.state.subCategory,subCategoryArabicName:e.target.value}})}}
                                        size='small'
                                        autoFocus={false}
                                        required={true}
                                    />
                                <div style={{ height:'230px',overflowY:'auto',overflowX:'hidden',width:'100%'}}>
                                    {this.state.subCategory.images.map((image, i) => {
                                        return (
                                            <Card style={{width:'380px',marginBottom:'10px'}}>
                                            <img 
                                                style={{width:'380px',height:'100px',objectFit:'cover'}} 
                                                src={image.url} 
                                                onClick = {(e)=>this.openImageViewer(e)}
                                                alt={''}/>
                                            </Card>
                                        )
                                    })}
                                    {this.state.subCategory.images.length==0?
                                        <div style={{width:'100%',height:'200px',display:'flex',flexDirection:'column'}}>
                                            <div style={{margin:'auto'}}>No images are added</div>
                                            </div>
                                        :null
                                    }
                                </div>
                            </div>
                        </Card>
                        <div className='d-flex flex-direction-row float-right' style={{marginTop:'10px'}}>
                        <Button
                                variant="contained"
                                startIcon={<CancelIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                style={{marginRight:'10px'}}
                                color="secondary"
                            >Cancel
                        </Button>

                        <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setState({confirm:{message:'Confirm',status:true}})
                                }}
                                color="primary"
                            >Delete
                        </Button>
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
                    {this.state.confirm.status?
                    <   ConfirmationBox
                        open={this.state.confirm.status}
                        message = {this.state.confirm.message}
                        handleClose = {(e)=>{this.setState({confirm:{...this.state.confirm,status:false}})}}
                        handleCloseConfirmed = {
                            (e)=>{
                                this.setState({confirm:{...this.state.confirm,status:false}})
                                this.deleteSubCategory()
                            }
                        }
                    />
                    :null    
                    }
                    {this.state.imageViewer.status?
                            <ImageViewer
                            imgs={ this.state.imageViewer.images }
                            currImg={0}
                            isOpen = {this.state.imageViewer.status}
                            onClose={ this.closeImageViewer }
                            />
                            : null    
                    }
                    </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default withSnackbar(DeleteSubCategory)