import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'

import Field from '../../common/Field'
import {deleteImages,deleteItem} from '../../../repository/ItemsRepository'
import LoadingScreen from '../../common/LoadingScreen'
import ConfirmationBox from '../../common/ConfirmationBox'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

class Delete extends Component {

    constructor(props){
        super(props)
        this.state={
            item:this.props.item,
            selectedCategory:this.props.selectedCategory,
            selectedSubCategories:[],
            loading:{
                status:false,
                message:''
            },
            confirm:{
                status:false,
                message:''
            },
            imageViewer:{
                images:'',
                status:false
            }

        }
        this.inputRef = React.createRef()
    }

    openImageViewer = (e) => {
        let arr = []
        let img = {src:e.target.src}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }

    deleteOldImage = (e) => {
        let arr = this.state.deletedImages.concat(e.currentTarget.value)
        this.setState({deletedImages:arr})
        let newImagesArray = this.state.item.images.filter(image => e.currentTarget.value!==image.id)
        this.setState({item:{...this.state.item, images:newImagesArray}})
    }



    deleteItem = async () => {

       try {if(this.state.item.images.length!==0){
             this.setState({loading:{status:true,message:'Deleting images'}})
             await deleteImages(this.state.item.images)
             this.props.enqueueSnackbar("Images deleted successfully",{variant:"success"})
       }
       this.setState({loading:{status:true,message:'Deleting item'}})
        await FirebaseApp.auth().currentUser.getIdToken(true).then(async (idToken) => {
                let param = {itemId: this.state.item.itemId}
                await deleteItem(param,idToken)
                this.props.enqueueSnackbar("Item deleted successfully",{variant:"success"})
                this.props.handleCloseWithRefresh()
              })
        }catch(error){
            this.props.enqueueSnackbar("error "+error,{variant:"warning"})
        }finally{
            this.setState({loading:{status:false,message:''}})
        }
    }

    
    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'850px',position:'relative',paddingBottom:'20px'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Delete Item</h4>
                        <br></br>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="d-flex flex-direction-row">
                                    <div style={{marginRight:'20px',marginLeft:'5px'}}>
                                    <FormControl variant="outlined" style={{width:'180px'}} disabled={true} >
                                        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.selectedCategory}
                                            onChange={(e)=>{this.selectCategory(e)}}
                                            autoWidth
                                            style={{fontSize:'12px',paddingLeft:"0px"}}
                                            displayEmpty={true}
                                            defaultValue={this.props.defaultValue}
                                            label="Category"
                                        >
                                            {this.props.categories.map((option, index) => {
                                            return(
                                                <MenuItem value={option['categoryId']}>{option['categoryName']}</MenuItem>
                                            )
                                                
                                        })}
                                            </Select>
                                    </FormControl>
                                    </div>
                                    <div style={{marginRight:'20px',marginLeft:'5px'}}>
                                    <FormControl variant="outlined" style={{width:'180px'}} disabled={true} >
                                        <InputLabel id="demo-simple-select-outlined-label">Sub Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.item.subCategoryId}
                                            onChange={(e)=> {this.selectSubCategory(e)}}
                                            autoWidth
                                            style={{fontSize:'12px'}}
                                            displayEmpty={true}
                                            defaultValue={this.props.defaultValue}
                                            label="Sub Category"
                                        >
                                            {this.props.selectedSubCategories.map((option, index) => {
                                            return(
                                                <MenuItem value={option['subCategoryId']}>{option['subCategoryName']}</MenuItem>
                                            )
                                                
                                        })}
                                            </Select>
                                    </FormControl>
                                    </div>
                                </div>
                                <Field
                                    type='text'
                                    id='name'
                                    placeholder='Item Name'
                                    size='small'
                                    value = {this.state.item.itemName}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,itemName:e.target.value}})}}
                                    autoFocus={false}
                                    disabled ={true}
                                />
                                <Field
                                    type='text'
                                    id='itemNameArabic'
                                    placeholder='item Arabic Name '
                                    value = {this.state.item.itemNameArabic}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,itemNameArabic:e.target.value}})}}
                                    size='small'
                                    autoFocus={false}
                                    disabled={true}
                                />
                                <Field
                                    type='text'
                                    id='description'
                                    placeholder='Item Description'
                                    size='small'
                                    value = {this.state.item.description}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,description:e.target.value}})}}
                                    autoFocus={false}
                                    multiline = {true}
                                    rows = {4}
                                    disabled ={true}
                                />
                                <Field
                                    type='text'
                                    id='itemDescriptionArabic'
                                    placeholder='Item Description Arabic'
                                    size='small'
                                    value = {this.state.item.itemDescriptionArabic}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,itemDescriptionArabic:e.target.value}})}}
                                    autoFocus={false}
                                    multiline = {true}
                                    rows = {4}
                                    disabled={true}
                                />
                               
                            </div>
                            <div className='col-lg-6'>
                            <Field
                                    type='text'
                                    id='unitType'
                                    placeholder='Unit Type'
                                    value = {this.state.item.unitType}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,unitType:e.target.value}})}}
                                    size='small'
                                    autoFocus={false}
                                    disabled ={true}
                                />
                                <Field
                                    type='text'
                                    id='unitTypeArabic'
                                    placeholder='Unit Type Arabic'
                                    value = {this.state.item.unitTypeArabic}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,unitTypeArabic:e.target.value}})}}
                                    size='small'
                                    autoFocus={false}
                                    disabled={true}
                                />
                                <Field
                                    type='text'
                                    id='unitPrice'
                                    placeholder='Unit Price'
                                    value = {this.state.item.unitPrice}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,unitPrice:e.target.value}})}}
                                    size='small'
                                    autoFocus={false}
                                    disabled ={true}
                                />
                            <div style={{ height:'200px',overflowY:'scroll',overflowX:'hidden',width:'100%'}}>
                                {this.state.item.images.map((image, i) => {
                                    return (
                                        <Card style={{width:'360px',marginBottom:'10px'}}>
                                            <img 
                                            style={{width:'360px',height:'100px',objectFit:'cover'}} 
                                            src={image.url} 
                                            onClick = {(e)=>this.openImageViewer(e)}
                                            alt={''}/>
                                        </Card>
                                    )
                                })}
                                {this.state.item.images.length===0?
                                     <div style={{width:'100%',height:'200px',display:'flex',flexDirection:'column'}}>
                                         <div style={{margin:'auto'}}>No images</div>
                                         </div>
                                    :null
                                }
                            </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='d-flex flex-direction-row float-right'>
                        <Button
                                variant="contained"
                                startIcon={<CancelIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                style={{marginRight:'10px'}}
                                color="secondary"
                            >Cancel
                        </Button>

                        <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setState({confirm:{message:'confirm',status:true}})
                                }}
                                color="primary"
                            >Delete Item
                        </Button>
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
                    {this.state.confirm.status?
                    <   ConfirmationBox
                        open={this.state.confirm.status}
                        message = {this.state.confirm.message}
                        handleClose = {(e)=>{this.setState({confirm:{...this.state.confirm,status:false}})}}
                        handleCloseConfirmed = {
                            (e)=>{
                                this.setState({confirm:{...this.state.confirm,status:false}})
                                this.deleteItem()
                            }
                            
                        }
                    />
                    :null    
                    }
                    {this.state.imageViewer.status?
                                <ImageViewer
                                    imgs={ this.state.imageViewer.images }
                                    currImg={0}
                                    isOpen = {this.state.imageViewer.status}
                                    onClose={ this.closeImageViewer }
                                />
                                : null    
                        }
                    </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default withSnackbar(Delete)