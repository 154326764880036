import {API} from '../service/RESTService'
import firebase from '../FirebaseSetup'
import UUIDGenerator from '../service/UUIDGenerator'

const ITEMS_ENDPOINT = 'api/v1/items'

var storage = firebase.storage()
var storageRef = storage.ref('items')

export const fetchItems = (params,idToken) => {
    return API.GET(ITEMS_ENDPOINT,idToken, params)
}

export const addItem = (item,idToken) => {
    let items = []
    items.push(item)
    let itemJSON = JSON.stringify(items)
    return API.POST(ITEMS_ENDPOINT,idToken,itemJSON)
    
}

export const deleteItem =(param,idToken) => {
    return API.DELETE(ITEMS_ENDPOINT,idToken,param)
}

export const uploadImages = async (images) => {
   try{ let uploadedImages = []
        for(let x=0; x<images.length;x++){
            let image = images[x]
            let imageId = UUIDGenerator().toString()
            let fileExtension = image.file.name.replace(/^.*\./, '');
            let imageRef = storageRef.child(imageId+'.'+fileExtension) 
            const res = await imageRef.put(image.file)
            const url = await res.ref.getDownloadURL()
            const imageData = {
                id: imageId+'.'+fileExtension,
                url:url
            }
            await uploadedImages.push(imageData)
        }
        return uploadedImages
    }catch(error){
        throw error //TODO check this error scenario by disabling internet
    }
}

export const deleteImages =async (images) => {
    try{for(let x=0; x<images.length;x++){
        let image = images[x]
        let imageRef = storageRef.child(image.id)
        await imageRef.delete()
        }
    }catch(error){
        throw error //TODO check this error scenario by disabling internet
    }
}