import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'

import Field from '../../common/Field'
import {deleteImages, uploadImages, addSlides} from '../../../repository/ConfigurationsRepository'
import _ from 'lodash'
import LoadingScreen from '../../common/LoadingScreen'
import ConfirmationBox from '../../common/ConfirmationBox'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

class Update extends Component {

    constructor(props){
        super(props)
        this.state={
            slide:{
                imageName:this.props.slide.imageName,
                imagePosition:this.props.slide.imagePosition,
                imageId:this.props.slide.imageId,
                url:this.props.slide.url
            },
            addedImages:[],
            deletedImages:[],
            loading:{
                status:false,
                message:''
            },
            fileInput:'',
            confirm:{
                status:false,
                message:''
            },
            imageViewer:{
                images:'',
                status:false
            }
        }
        this.inputRef = React.createRef()
    }

    openImageViewer = (e) => {
        let arr = []
        let img = {src:e.target.src}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }

    addImage = (e) => {
        let imageFile = {
            url : URL.createObjectURL(e.target.files[0]),
            file : e.target.files[0]
        }
        let arr = this.state.addedImages.concat(imageFile)
        this.setState({ addedImages:arr})
    }

    deleteNewImage = (e) => {
        let arr =  this.state.addedImages.filter(file => e.currentTarget.value!=file.url)
        this.setState({addedImages:arr})
    }

    deleteOldImage = () => {
        let arr = this.state.deletedImages
        let image = {
            id: this.state.slide.imageId
        }
        arr.push(image)
        this.setState({deleteImages:arr})
        this.setState({slide:{...this.state.slide, url:'', imageId:''}})
    }

    validate = () => {
        if(
            this.state.slide.imageName =='' &&
            (this.state.addedImages.length==0||this.state.slide.url=='')
        ){
            this.props.enqueueSnackbar("Cant leave importnant fields empty",{variant:'warning'})
            return false
        }else{
            return true
        }
    }

    updateSlide = async () => {
       try{
           console.log(this.props.data.slidesList)
        if(this.validate()){
            let newSlide = {
                imageName : this.state.slide.imageName,
                imageId:this.state.slide.imageId,
                imagePosition:this.state.slide.imagePosition,
                url:this.state.slide.url
            }

            let slides = this.props.data

            if(this.state.deletedImages.length!=0){
                this.setState({loading:{status:true,message:'Deleting old images'}})
                await deleteImages(this.state.deletedImages,"slides")
            }

            if(this.state.addedImages.length!=0){
                this.setState({loading:{status:true,message:'Uploading images'}})
                let uploadedImages = await uploadImages(this.state.addedImages,"slides")
                let images= await uploadedImages
                let newImage = images[0]
                newSlide['url'] = newImage.url
                newSlide['imageId'] = newImage.id
                this.props.enqueueSnackbar("Images are uploaded successfully",{variant:"success"})
            }
            
            slides.slidesList.splice(this.state.slide.imagePosition-1,1,newSlide)

            this.setState({loading:{status:true,message:'Updating slides'}})
            await FirebaseApp.auth().currentUser.getIdToken(true).then(async (idToken) => {
                    await addSlides(idToken,slides)
                  })
                  this.props.enqueueSnackbar("Slide was updated succesfully",{variant:'success'})
            this.props.handleCloseWithRefresh()

            }
       }catch(error){
        this.props.enqueueSnackbar("Update slide was failed"+error,{variant:'error'})
       }finally{
        this.setState({loading:{status:false,message:''}})
       }
    }
    
    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'450px',position:'relative',paddingBottom:'20px'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Update Slide</h4>
                        <br></br>
                        <div className='col-lg-12'>
                            
                                <Field
                                    type='text'
                                    id='name'
                                    placeholder='Image Name'
                                    size='small'
                                    value = {this.state.slide.imageName}
                                    onChange = {(e)=>{this.setState({slide:{...this.state.slide,imageName:e.target.value}})}}
                                    autoFocus={true}
                                    required={true}
                                />
                                
                            <div>
                               {this.state.addedImages.length==0 && this.state.slide.url==''?
                                    <div className="d-flex flex-direction-row" style={{width:'100%',marginBottom:'10px'}}>
                                         <Button
                                            variant="contained"
                                            startIcon={<AddIcon/>}
                                            size = "small"
                                            onClick = {(e) => {
                                                this.inputRef.current.click()
                                            }}
                                     color="primary"
                                     style={{marginLeft:'auto'}}
                                    >Add Image
                                </Button>
                                <input 
                                    hidden id="fileUpload" 
                                    type="file"  
                                    value={this.state.fileInput}
                                    ref = {this.inputRef} 
                                    accept="image/x-png,image/gif,image/jpeg" 
                                    onChange = {(e)=> {this.addImage(e)}} 
                                />   
                                    </div>
                                    : null
                            }
                            </div>
                            <div style={{ height:'100px',overflowY:'auto',overflowX:'hidden',width:'100%'}}>
                                {this.state.addedImages.map((image, i) => {
                                    return (
                                        <Card style={{width:'380px'}}>
                                            <img
                                                style={{width:'300px',height:'100px',objectFit:'cover'}} 
                                                src={image.url} 
                                                onClick = {(e)=>this.openImageViewer(e)}
                                                alt={''}/>
                                            <IconButton 
                                                id={image.url}
                                                aria-label="delete" 
                                                style={{marginLeft:'15px'}}
                                                value = {image.url}
                                                onClick = {(e) => {this.deleteNewImage(e)}}
                                                >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Card>
                                    )
                                })}
                                {this.state.slide.url!==''?
                                    <Card style={{width:'380px'}}>
                                        <img 
                                        style={{width:'300px',height:'100px',objectFit:'cover'}} 
                                        src={this.state.slide.url} 
                                        onClick = {(e)=>this.openImageViewer(e)}
                                        alt={''}/>
                                        <IconButton 
                                            id={this.state.slide.url}
                                            aria-label="delete" 
                                            style={{marginLeft:'15px'}}
                                            value = {this.state.slide.url}
                                            onClick = {(e) => {this.deleteOldImage()}}
                                            >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Card>
                                : null
                                }
                                {this.state.addedImages.length==0 && this.state.slide.url==''?
                                     <div style={{width:'100%',height:'100px',display:'flex',flexDirection:'column'}}>
                                         <div style={{margin:'auto'}}>No images are added</div>
                                         </div>
                                    :null
                                }
                            </div>
                           
                            
                        </div>
                        <br></br>
                        <div className='d-flex flex-direction-row float-right'>
                        <Button
                                variant="contained"
                                startIcon={<CancelIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                style={{marginRight:'10px'}}
                                color="secondary"
                            >Cancel
                        </Button>

                        <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setState({confirm:{message:'Confirm',status:true}})
                                }}
                                color="primary"
                            >Update
                        </Button>
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='100px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
                    {this.state.confirm.status?
                    <   ConfirmationBox
                        open={this.state.confirm.status}
                        message = {this.state.confirm.message}
                        handleClose = {(e)=>{this.setState({confirm:{...this.state.confirm,status:false}})}}
                        handleCloseConfirmed = {
                            (e)=>{
                                this.setState({confirm:{...this.state.confirm,status:false}})
                                this.updateSlide()
                            }
                            
                        }
                    />
                    :null    
                    }
            {this.state.imageViewer.status?
                <ImageViewer
                imgs={ this.state.imageViewer.images }
                currImg={0}
                isOpen = {this.state.imageViewer.status}
                onClose={ this.closeImageViewer }
                />
                : null    
        }
                    </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default withSnackbar(Update)