import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import moment from 'moment-timezone'
import {timeZone} from '../../service/env'
import ConfirmationBox from '../common/ConfirmationBox'

//material-ui components
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import {TableWithActions} from './../common/TableView'
import {FilterField, FilterDropDown,FilterDatePicker} from './../common/FilterField'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'

//styling
import {withStyles} from '@material-ui/core/styles'

//Context
import {setPendingPayments,setPendingPaymentsFilter} from '../../context/orders/Actions'
import {OrderContext} from '../../context/orders/Context'

//firebase
import FirebaseApp from '../../FirebaseSetup'

//orderRepository
import {fetchOrders,setAsPaid} from '../../repository/OrdersRepository'

import LoadingScreen from '../common/LoadingScreen'



const columns = [
        {title : 'Invoice No', field:'invoiceNo'},
        {title : 'Provider', field:'providerId', render:rowData=><div>{rowData.providerName}<br></br>{rowData.providerId}</div>},
        {title : 'Payment Due Date', field:'paymentDueDate',render:rowData=><div>{Date(rowData.paymentDueDate).toString().slice(0, 21)}</div>},
        {title : 'Pending Amount', field:'orderTotalWithoutSC'}
]
const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}



class PendingPayments extends Component{

        constructor(props) {
            super(props)

            this.state = {
                    status:'COMPLETED',
                    paymentStatus:'PENDING',
                    selectedPayment:'',
                    invoiceNo:{
                        value:''
                    },
                    provider:{
                        value:'',
                        type:'id',
                    },
                    loading:{
                        status:false,
                        message:''
                    },
                    confirm:false
                       
                    
            }
        }
    static contextType = OrderContext

    setParams = () => {
        let params = {}
        if(this.state.provider.value!=''){
            if(this.state.provider.type==='id'){
                params['providerId'] = this.state.provider.value
            }else{
                params['providerName'] = this.state.provider.value
            }
        }if(this.state.invoiceNo.value!=''){
            params['invoiceNo'] = this.state.invoiceNo.value
        }
        params['orderStatus'] = this.state.status
        params['paymentStatus'] = this.state.paymentStatus
        return params
    }

    saveFilter = () => {
        const {dispatch} = this.context

        let filter = {}
        filter['status'] = this.state.status
        filter['paymentStatus'] = this.state.paymentStatus
        filter['invoiceNo'] = {
            value:this.state.invoiceNo.value
        }
        filter['provider'] = {
            type:this.state.provider.type,
            value:this.state.provider.value
        }
        dispatch(setPendingPaymentsFilter(filter))
    }

    setFilter = () => {
        const {orders} = this.context
        let filter = orders.pendingPaymentsFilter
        this.setState({invoiceNo:filter.invoiceNo})
        this.setState({provider:filter.provider})
    }

    getOrders = async () => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting payments'}})
        let params = this.setParams()
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchOrders(params,idToken).then((data) => {
                        dispatch(setPendingPayments(data))
                        this.setState({loading:{status:false,message:''}})
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Import payments failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Import payments failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
    }

    setAsPaid = async () => {
        let params = {
            orderId : this.state.selectedPayment.orderId,
            providerId : this.state.selectedPayment.providerId,
            orderStatus : "COMPLETED",
            paymentStatus : "COMPLETED",
            paymentDueDate : moment(this.state.selectedPayment.paymentDueDate).tz(timeZone).format('yyyy-MM-DD HH:mm:ss').toString()
        }
        try{
            this.setState({loading:{status:true,message:''}})
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                setAsPaid(params,idToken).then((data) => {
                        this.setState({loading:{status:false,message:''}})
                        this.setState({confirm:false, selectedPayment:''})
                            this.getOrders()
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Action failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Action failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
    }

    componentDidMount = () => {
        const {orders} = this.context
        this.setFilter()
        if(orders.pendingPayments.length==0){
            this.getOrders()
        }
    }

    render(){
        const {classes} = this.props
        const {orders} = this.context
        return(
            <div>
                 <div style = {styles.headingContainer}>
                <h4> <span>Pending Payments</span></h4>
                </div>
                <br></br>
                <div  className ="d-flex flex-row">
                    <Card>
                    <div className="w-100 d-flex flex-row">
                            <div style={{width:'180px',marginTop:'18px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "Invoice No"
                                    placeholder = "Invoice No"
                                    value = {this.state.invoiceNo.value}
                                    onChange = { e => {this.setState( {invoiceNo : {value:e.target.value}})}}
                                    />
                            </div>
                            <div style={{width:'180px',marginTop:'18px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "Provider"
                                    placeholder = "Provider"
                                    value = {this.state.provider.value}
                                    onChange = { e => {this.setState( {provider : {value:e.target.value,type:this.state.provider.type}})}}
                                    />
                                <div>
                                    <FilterDropDown 
                                                value={this.state.provider.type}
                                                options ={ [{name:'id',label:'By ID'}, {name:'name', label:'By Name'}]}
                                                onChange = {(e) => {this.setState({provider : {type:e.target.value,value:this.state.provider.value}})}}
                                                />
                                    </div>
                            </div>
                        <div style={{alignSelf:'center',margin:'10px',marginRight:'20px'}}>
                            <Button
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.saveFilter()
                                    this.getOrders()
                                }}
                            >Filter
                            </Button>
                        </div> 

                    </div>
                    </Card>
                </div>
                <Divider style={styles.divider}/>
                <div>

                <TableWithActions
                    columns = {columns}
                    data = {orders.pendingPayments}
                    actions = {[
                        {
                            icon: ThumbUpIcon,
                            tooltip: 'Mark as paid',
                            onClick:(e,rowData)=> {
                                this.setState({selectedPayment:rowData})
                                this.setState({confirm:true})

                            }
                        },
                    ]}
                />


            </div>
            {this.state.confirm?
                    <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.state.confirm}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.confirm}>
                    <div style={{backgroundColor:'white',width:'300px',position:'relative'}}
                    className="bg-light"
                    >
                        <Card style={{padding:'20px'}}> 
                            <div className="row d-flex flex-direction-row" style={{padding:'10px',height:'150px'}}>
                                <div style={{padding:'10px'}}>
                                    <h6>Set paid date</h6>
                                    <FilterDatePicker
                                    id="to"
                                    label=""
                                    value={this.state.selectedPayment.paymentDueDate}
                                    onChange={(date) => this.setState({selectedPayment:{ ...this.state.selectedPayment, paymentDueDate:date}})}
                                    />
                                </div>
                            </div>
                            <div className="row d-flex flex-direction-row" style={{paddingRight:'10px'}}>
                                <Button
                                        variant="contained"
                                        startIcon={<CancelIcon/>}
                                        size = "small"
                                        onClick = {(e) => {
                                            this.setState({confirm:false})
                                        }}
                                        style={{marginLeft:'auto',marginRight:'10px'}}
                                        color="secondary"
                                    >Cancel
                                </Button>

                                <Button
                                        variant="contained"
                                        startIcon={<CheckCircleIcon/>}
                                        size = "small"
                                        onClick = {(e) => {
                                            this.setAsPaid()
                                        }}
                                        color="primary"
                                    >confirm
                                </Button>
                            </div>
                        </Card>
                       </div>
                    </Fade>
                </Modal>
                    :null    
                    }
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='300px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            </div>
        )
    }
}

export default withSnackbar((PendingPayments))