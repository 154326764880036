
export const getProviders = providers => ({
    type: "GET_PROVIDERS",
    providers
})

export const getCustomers = customers => ({
    type: "GET_CUSTOMERS",
    customers
})

export const setPendingProvidersFilter = filter => ({
    type: "SET_PENDING_PROVIDERS_FILTER",
    filter
})

export const setApprovedProvidersFilter = filter =>({
    type: "SET_APPROVED_PROVIDERS_FILTER",
    filter
})

export const setCustomersFilter = filter => ({
    type: "SET_CUSTOMERS_FILTER",
    filter
})
