import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

//importing pages
import AdminWrapper from './AdminWrapper'
import Dashboard from './Dashboard'

import PendingOrders from './PendingOrders'
import CompletedOrders from './CompletedOrders'
import CancelledOrders from './CancelledOrders'
import ManageItems from './ManageItems/Index'
import ManageCategories from './manageCategories/Index'
import ManageFeeds from './manageFeeds/Index'
import ManageSlides from './manageSlides/Index'
import ManageProviders from './manageProviders/Index'
import ManageCutomers from './ManageCustomers'
import ManageCustomers from './ManageCustomers'
import PendingPayments from './PendingPayments'
import CompletedPayments from './CompletedPayments'
import Settings from './Settings'

const AppLayout = (props) => {
    return(
        <div>
          <Router>
            <Route
              path="/"
              render = {props => {
                   
                return (
                  <div>                    
                    <AdminWrapper>
                        <Route
                          path="/pendingOrders"
                          exact={true}
                          render = {props => (
                            <PendingOrders/>
                          )}
                        />

                        <Route
                          path="/completedOrders"
                          exact={true}
                          render = {props => (
                            <CompletedOrders/>
                          )}
                        />

                        <Route
                          path="/cancelledOrders"
                          exact={true}
                          render = {props => (
                            <CancelledOrders/>
                          )}
                        />
                        <Route
                          path="/manageItems"
                          exact={true}
                          render = {props => (
                            <ManageItems/>
                          )}/>
                          <Route
                          path="/manageCategories"
                          exact={true}
                          render = {props => (
                            <ManageCategories/>
                          )}
                        />
                        <Route
                          path="/manageSlides"
                          exact={true}
                          render = {props => (
                            <ManageSlides/>
                          )}
                        />
                        <Route
                          path="/listOfProviders"
                          exact={true}
                          render = {props => (
                            <ManageProviders/>
                          )}
                        />
                        <Route
                          path="/manageCustomers"
                          exact={true}
                          render = {props => (
                            <ManageCustomers/>
                          )}
                        />
                        <Route
                          path="/manageFeeds"
                          exact={true}
                          render = {props => (
                            <ManageFeeds/>
                          )}
                        />
                        <Route
                          path="/pendingPayments"
                          exact={true}
                          render = {props => (
                            <PendingPayments/>
                          )}
                        />
                        <Route
                          path="/completedPayments"
                          exact={true}
                          render = {props => (
                            <CompletedPayments/>
                          )}
                        />
                         <Route
                          path="/settings"
                          exact={true}
                          render = {props => (
                            <Settings/>
                          )}
                        />
                        <Route
                          path="/"
                          exact={true}
                          render = {props => (
                            <Dashboard/>
                          )}
                        />
                    </AdminWrapper> 
                  </div>
                )
              }}/>
          </Router>
        </div>
      
    )
}

export default AppLayout;