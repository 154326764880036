import React, {Component} from 'react'
import {withSnackbar } from 'notistack'

import Field from '../../common/Field'
import {uploadImages,deleteImages, addCategory} from '../../../repository/CategoriesRepository'
import _ from 'lodash'
import LoadingScreen from '../../common/LoadingScreen'
import { Category } from '../../../models/Category'
import {TableWithActions} from '../../common/TableView'
import AddSubCategory from './AddSubCategory'
import UpdateSubCategory from './UpdateSubCategory'
import DeleteSubCategory from './DeleteSubCategory'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

const columns = [
    {title : 'Sub Category Name',field:'subCategoryName'},
    {title : 'Arabic Name', field:'subCategoryArabicName'}
]

class ManageSubCategories extends Component {

    constructor(props){
        super(props)
        this.state={
            subCategory:{
              data:'',
              add:false,
              update:false,
              delete:false,  
            },
            categoryId:'',
            subCategories:'',
            loading:{
                status:false,
                message:''
            },
            fileInput:''
        }
        this.inputRef = React.createRef()
    }

    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={false}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'700px',position:'relative',paddingBottom:'20px',overflowY:'auto'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Manage Sub Categories</h4>
                        <div style={{marginBottom:'10px',marginTop:'20px'}} className="d-flex flex-direction-row" >
                            <Button
                                        variant="contained"
                                        startIcon={<AddIcon/>}
                                        size = "small"
                                        onClick = {(e) => {
                                            this.setState({subCategory:{...this.state.subCategory, add:true}})
                                        }}
                                        color="primary"
                                        style={{marginLeft:'auto'}}
                                    >Add
                                </Button>
                        </div>
                       <div style={{height:'360px',overflowY:'auto'}}>
                        <TableWithActions
                                columns = {columns}
                                data = {this.props.category.subCategoryList}
                                actions = {[
                                    {
                                        icon: Edit,
                                        tooltip: 'Update',
                                        onClick: (e,rowData)=>{this.setState({subCategory:{...this.subCategory, data:rowData, update:true}})}
                                    },
                                    {
                                        icon: DeleteOutline,
                                        tooltip: 'Remove',
                                        onClick: (e,rowData)=>{this.setState({subCategory:{...this.subCategory, data:rowData, delete:true}})}
                                    },
                                ]}
                            />
                        </div>
                        <div className='d-flex flex-direction-row float-right' style={{marginTop:'10px'}}>
                        <Button
                                variant="contained"
                                startIcon={<KeyboardBackspaceIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                color="primary"
                            >Back
                        </Button>
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
                    </div>
                    </Fade>
                </Modal>
                {this.state.subCategory.add?
                <AddSubCategory
                    categoryId={this.props.category.categoryId}
                    open={this.state.subCategory.add}
                    handleClose = {(e) => {this.setState({subCategory:{...this.state.subCategory, add:false}})}}
                    handleCloseWithRefresh = {(e) => {
                        this.setState({subCategory:{...this.state.subCategory, add:false}})
                        this.props.handleCloseWithRefresh()
                    }}
                />
                :null
                }
                {this.state.subCategory.update?
                <UpdateSubCategory
                    categoryId={this.props.category.categoryId}
                    subCategory = {this.state.subCategory.data}
                    open={this.state.subCategory.update}
                    handleClose = {(e) => {this.setState({subCategory:{...this.state.subCategory, update:false}})}}
                    handleCloseWithRefresh = {(e) => {
                        this.setState({subCategory:{...this.state.subCategory, update:false}})
                        this.props.handleCloseWithRefresh()
                    }}
                />
                :null
                }
                {this.state.subCategory.delete?
                <DeleteSubCategory
                    categoryId={this.props.category.categoryId}
                    subCategory = {this.state.subCategory.data}
                    open={this.state.subCategory.delete}
                    handleClose = {(e) => {this.setState({subCategory:{...this.state.subCategory, delete:false}})}}
                    handleCloseWithRefresh = {(e) => {
                        this.setState({subCategory:{...this.state.subCategory, delete:false}})
                        this.props.handleCloseWithRefresh()
                    }}
                />
                :null
                }
            </div>
        )
    }
}
export default withSnackbar(ManageSubCategories)