import React, {createContext, useReducer} from 'react'
import OrderReducer from './Reducers'

export const OrderContext = createContext();

const OrderContextProvider = (props) => {
    const [orders, dispatch] = useReducer(OrderReducer, {
            pendingOrders:[],
            pendingOrdersFilter:{
                status:'PENDING',
                    invoiceNo:{
                        value:''
                    },
                    provider:{
                        value:'',
                        type:'id',
                    },
                    customer:{
                        value:'',
                        type:'id',
                    },
                    to:new Date().toISOString().replace("T", " ").slice(0, 23),
                    from:new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().replace("T", " ").slice(0, 23),
            },
            cancelledOrders:[],
            cancelledOrdersFilter:{
                status:'CANCELLED',
                    invoiceNo:{
                        value:''
                    },
                    provider:{
                        value:'',
                        type:'id',
                    },
                    customer:{
                        value:'',
                        type:'id',
                    },
                    to:new Date().toISOString().replace("T", " ").slice(0, 23),
                    from:new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().replace("T", " ").slice(0, 23),
            },
            completedOrders:[],
            completedOrdersFilter:{
                status:'COMPLETED',
                    invoiceNo:{
                        value:''
                    },
                    provider:{
                        value:'',
                        type:'id',
                    },
                    customer:{
                        value:'',
                        type:'id',
                    },
                    to:new Date().toISOString().replace("T", " ").slice(0, 23),
                    from:new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().replace("T", " ").slice(0, 23),
            },
            pendingPayments:[],
            pendingPaymentsFilter:{
                status:'COMPLETED',
                paymentStatus:'PENDING',
                invoiceNo:{
                    value:''
                },
                provider:{
                    value:'',
                    type:'id',
                }
            },
            completedPayments:[],
            completedPaymentsFilter:{
                status:'COMPLETED',
                paymentStatus:'COMPLETED',
                invoiceNo:{
                    value:''
                },
                provider:{
                    value:'',
                    type:'id',
                },
            }

    })


    return (
        <OrderContext.Provider value={{orders, dispatch}}>
           {props.children}
        </OrderContext.Provider>
    );
}

export default OrderContextProvider;
