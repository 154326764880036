import React, { Component } from 'react'
import { withSnackbar } from 'notistack'

import LoadingScreen from '../../common/LoadingScreen'
import Update from './Update'
import Add from './Add'
import Delete from './Delete'

//material-ui components
import Divider from '@material-ui/core/Divider'
import { TableWithActions } from '../../common/TableView'
import { FilterField, FilterDropDownWithLabel } from '../../common/FilterField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'

//styling
import { withStyles } from '@material-ui/core/styles'

//import context
import { ConfigurationsContext } from '../../../context/configurations/Context'
import { getFeeds, setFeedsFilter } from '../../../context/configurations/Actions'


//feeds repository
import { fetchFeeds } from '../../../repository/ConfigurationsRepository'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

const columns = [
    { title: 'Feed ID', field: 'feedId' },
    { title: 'Feed Name', field: 'feedName' },
    { title: 'Feed Type', field: 'feedType' },
    { title: 'Active Days', field: 'activeDays',render:rowData=>{
        const date1 = new Date();
        const date2 = new Date(rowData.activeDays);
        const diffTime = date2 - date1;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        console.log(diffDays + " days");
        if(diffDays>0){
            return(<div>
                <div style={{color:"green"}}>{diffDays} days remaining</div>
                <div>{date2.toString().slice(0,16)}</div>
            </div>)
        }else if(diffDays==0){
            return(
                <div>
                    <div style={{color:"green"}}>Today</div>
                    <div>{date2.toString().slice(0,16)}</div>
                </div>
            )
        }
        else{
            return(<div>
                <div style={{color:"red"}}>Expired</div>
                <div>{date2.toString().slice(0,16)}</div>
            </div>)
        }
        
    } },
    { title: 'Status', field: 'status' }
]

const styles = {
    headingContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop: '0',
    },
    divider: {
        marginTop: '10px',
        marginBottom: '10px'
    }
}

const newStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectOptions: {
        color: 'green',
        backgroundColor: 'yellow'
    }
})

class ManageFeeds extends Component {

    constructor(props) {
        super(props)

        this.state = {
            actions: {
                data: '',
                update: false,
                delete: false,
                add: false,
            },
            filter: {
                name: '',
                id: '',
            },
            feeds:[],
            loading: {
                status: false,
                message: ''
            },

        }
    }
    static contextType = ConfigurationsContext

    //fetch feeds
    getData = () => {
        const { dispatch } = this.context
        this.setState({ loading: { status: true, message: 'Getting feeds details' } })
        try {
            FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchFeeds({}, idToken).then((data) => {
                    dispatch(getFeeds(data))
                    this.setParams()
                    this.setState({ loading: { status: false, message: '' } })
                })
            })
        } catch (error) {
            this.props.enqueueSnackbar("Importing feeds failed" + error, { variant: 'error' })
            this.setState({ loading: { status: false, message: '' } })
        }

    }

    setParams = () => {
        const { configurations } = this.context
        let params = {}
        if (this.state.filter.name != '') {
            params['feedName'] = this.state.filter.name
        }
        if (this.state.filter.id != '') {
            params['feedId'] = this.state.filter.id
        }
        let arr = configurations.feeds.feedList.filter(customer => {
            for (const [key, value] of Object.entries(params)) {
                if (customer[key] != value) {
                    return false
                }
            }
            return true
        })
        this.setState({ feeds: arr })
    }

    saveFilter = () => {
        const { dispatch } = this.context
        dispatch(setFeedsFilter(this.state.filter))
    }

    setFilter = () => {
        const { configurations } = this.context
        this.setState({ filter: configurations.feedsFilter })

    }

    componentDidMount() {
        const { configurations } = this.context
        this.setFilter()
       // if (configurations.feed=={}) {
            this.getData()
       // }
        
    }

    render() {
        const { classes } = this.props
        const { configurations } = this.context
        return (
            <div style={{ position: 'relative' }}>
                <div style={styles.headingContainer}>
                    <h4> <span>Manage Feeds</span></h4>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        style={{ marginLeft: 'auto' }}
                        startIcon={<AddIcon />}
                        onClick={(e) => { this.setState({actions:{...this.actions, add:true}})}}
                    >
                        Add Feed
                    </Button>
                </div>
                <br></br>
                <div className="d-flex flex-row">
                    <Card>
                        <div className="w-100 d-flex flex-row">
                            <div style={{ width: '180px', marginTop: '16px', marginRight: '15px' }}>
                                <FilterField
                                    id="feedId"
                   a                 label="Feed ID"
                                    value={this.state.filter.id}
                                    onChange={(e) => { this.setState({ filter: { ...this.state.filter, id: e.target.value } }) 
                                }}
                                />
                            </div>
                            <div style={{ width: '180px', marginTop: '16px', marginRight: '15px' }}>
                                <FilterField
                                    id="feedName"
                                    label="Feed Name"
                                    value={this.state.filter.name}
                                    onChange={(e) => { this.setState({ filter: { ...this.state.filter, name: e.target.value } }) }}
                                />
                            </div>
                            <div style={{ alignSelf: 'center', marginTop: '18px', marginRight: '15px' }}>
                                <Button
                                    variant="contained"
                                    startIcon={<FilterListIcon />}
                                    size="small"
                                    onClick={(e) => {
                                        this.saveFilter()
                                        this.setParams()
                                    }}
                                >Filter
                            </Button>
                            </div>
                            <div style={{ alignSelf: 'center', marginTop: '18px', marginRight: '15px' }}>
                                <Button
                                    variant="contained"
                                    startIcon={<FilterListIcon />}
                                    size="small"
                                    onClick={(e) => {
                                        this.saveFilter()
                                        this.getData()

                                    }}
                                >Refresh
                            </Button>
                            </div>
                        </div>
                    </Card>

                </div>
                <Divider style={styles.divider} />
                <div style={{ position: 'relative' }}>

                    <TableWithActions
                        columns={columns}
                        data={this.state.feeds}
                        actions={[
                            {
                                icon: Edit,
                                tooltip: 'Update',
                                onClick: (e, rowData) => { this.setState({ actions: { ...this.state.actions, data: rowData, update: true } }) }
                            },
                            {
                                icon: DeleteOutline,
                                tooltip: 'Remove',
                                onClick: (e, rowData) => { this.setState({ actions: { ...this.state.actions, data: rowData, delete: true } }) }
                            }
                        ]}
                    />
                </div>
                {this.state.loading.status ?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY='200px'
                        message={this.state.loading.message}
                    />
                    : null
                }
                {this.state.actions.update ?
                    <Update
                        open={this.state.actions.update}
                        feed = {this.state.actions.data}
                        feedList = {configurations.feeds}
                        handleClose={(e) => {
                            this.setState({ actions: { ...this.state.actions, update: false } })
                        }}
                        handleCloseWithRefresh={(e) => {
                            this.setState({ actions: { ...this.state.actions, update: false } })
                            this.getData()
                        }}
                    />
                    : null
                }
                {this.state.actions.add ?
                    <Add
                        open={this.state.actions.add}
                        feedList = {configurations.feeds}
                        handleClose={(e) => {
                            this.setState({ actions: { ...this.state.actions, add: false } })
                        }}
                        handleCloseWithRefresh={(e) => {
                            this.setState({ actions: { ...this.state.actions, add: false } })
                            this.getData()
                        }}
                    />
                    : null
                }
                {this.state.actions.delete ?
                    <Delete
                        open={this.state.actions.delete}
                        feed = {this.state.actions.data}
                        feedList = {configurations.feeds}
                        handleClose={(e) => {
                            this.setState({ actions: { ...this.state.actions, delete: false } })
                        }}
                        handleCloseWithRefresh={(e) => {
                            this.setState({ actions: { ...this.state.actions, delete: false } })
                            this.getData()
                        }}
                    />
                    : null
                }
            </div>
        )
    }
}

export default withSnackbar((withStyles(newStyles)(ManageFeeds)))

