import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import moment from 'moment-timezone'
import {timeZone} from '../../service/env'

//material-ui components
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import {TableWithActions} from './../common/TableView'
import {FilterField, FilterDropDown,FilterDatePicker} from './../common/FilterField'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
//styling
import {withStyles} from '@material-ui/core/styles'

//Context
import {getCompletedOrders,setCompletedOrdersFilter} from '../../context/orders/Actions'
import {OrderContext} from '../../context/orders/Context'

//firebase
import FirebaseApp from '../../FirebaseSetup'

//orderRepository
import {fetchOrders} from '../../repository/OrdersRepository'

import LoadingScreen from '../common/LoadingScreen'


const columns = [
        {title : 'Invoice No', field:'invoiceNo'},
        {title : 'Requested By', field:'customerId',render:rowData=><div>{rowData.customerName}<br></br>{rowData.customerId}</div>},
        {title : 'Accepted Provider', field:'providerId', render:rowData=><div>{rowData.providerName}<br></br>{rowData.providerId}</div>},
        {title : 'Requested Time', field:'createdAt',render:rowData=><div>{Date(rowData.createdAt).toString().slice(0, 21)}</div>},
        {title : 'Total Order Value', field:'totalOrderAmount'},
]
const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}


class CompletedOrders extends Component{

        constructor(props) {
            super(props)

            this.state = {
                    status:'COMPLETED',
                    invoiceNo:{
                        value:''
                    },
                    provider:{
                        value:'',
                        type:'id',
                    },
                    customer:{
                        value:'',
                        type:'id',
                    },
                    to:new Date().toISOString().replace("T", " ").slice(0, 23),
                    from:new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().replace("T", " ").slice(0, 23),
                    loading:{
                        status:false,
                        message:''
                    },
            }
        }
    static contextType = OrderContext

    setParams = () => {
        let params = {}
        if(this.state.provider.value!=''){
            if(this.state.provider.type==='id'){
                params['providerId'] = this.state.provider.value
            }else{
                params['providerName'] = this.state.provider.value
            }
        }if(this.state.customer.value!=''){
            if(this.state.customer.type==='id'){
                params['customerId'] = this.state.customer.value
            }else{
                params['customerName'] = this.state.customer.value
            }
        }if(this.state.invoiceNo.value!=''){
            params['invoiceNo'] = this.state.invoiceNo.value
        }
        params['from'] = moment(this.state.from).tz(timeZone).format('yyyy-MM-DD HH:mm:ss').toString()
        params['to'] = (moment(this.state.to).add(1, 'days').tz(timeZone).format('yyyy-MM-DD HH:mm:ss')).toString()
        params['orderStatus'] = this.state.status
        return params
    }

    saveFilter = () => {
        const {dispatch} = this.context

        let filter = {}
        filter['status'] = this.state.status
        filter['invoiceNo'] = {
            value:this.state.invoiceNo.value
        }
        filter['provider'] = {
            type:this.state.provider.type,
            value:this.state.provider.value
        }
        filter['customer'] = {
            type: this.state.customer.type,
            value: this.state.customer.value
        }
        filter['from'] = this.state.from
        filter['to'] = this.state.to
        dispatch(setCompletedOrdersFilter(filter))
    }

    setFilter = () => {
        const {orders} = this.context
        let filter = orders.completedOrdersFilter
        this.setState({invoiceNo:filter.invoiceNo})
        this.setState({provider:filter.provider})
        this.setState({customer:filter.customer})
        this.setState({from:filter.from})
        this.setState({to:filter.to})
    }

    getOrders = async () => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting orders'}})
        let params = this.setParams()
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchOrders(params,idToken).then((data) => {
                        dispatch(getCompletedOrders(data))
                        this.setState({loading:{status:false,message:''}})
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Import orders failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Import orders failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }
    }

    componentDidMount = () => {
        const {orders} = this.context
        this.setFilter()
        if(orders.completedOrders.length==0){
            this.getOrders()
        }
    }

    render(){
        const {classes} = this.props
        const {orders} = this.context
        return(
            <div>
                 <div style = {styles.headingContainer}>
                <h4> <span>Completed Orders</span></h4>
                </div>
                <br></br>
                <div  className ="d-flex flex-row">
                    <Card>
                    <div className="w-100 d-flex flex-row">
                            <div style={{width:'180px',marginTop:'18px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "Invoice No"
                                    placeholder = "Invoice No"
                                    value = {this.state.invoiceNo.value}
                                    onChange = { e => {this.setState( {invoiceNo : {value:e.target.value}})}}
                                    />
                            </div>
                            <div style={{width:'180px',marginTop:'18px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "Customer"
                                    placeholder = "Customer"
                                    value = {this.state.customer.value}
                                    onChange = { e => {this.setState( {customer : {value:e.target.value,type:this.state.customer.type}})}}
                                    />
                                <div>
                                    <FilterDropDown 
                                                value={this.state.customer.type}
                                                options ={ [{name:'id',label:'By ID'}, {name:'name', label:'By Name'}]}
                                                onChange = {(e) => {this.setState({customer : {type:e.target.value,value:this.state.customer.value}})}}
                                                />
                                    </div>
                            </div>
                            <div style={{width:'180px',marginTop:'18px'}}>
                            <FilterField 
                                    disabled={false}
                                    label = "Provider"
                                    placeholder = "Provider"
                                    value = {this.state.provider.value}
                                    onChange = { e => {this.setState( {provider : {value:e.target.value,type:this.state.provider.type}})}}
                                    />
                                <div>
                                    <FilterDropDown 
                                                value={this.state.provider.type}
                                                options ={ [{name:'id',label:'By ID'}, {name:'name', label:'By Name'}]}
                                                onChange = {(e) => {this.setState({provider : {type:e.target.value,value:this.state.provider.value}})}}
                                                />
                                    </div>
                            </div>
                            <div style={{width:'180px',marginRight:'10px',marginLeft:'10px'}}>
                            <FilterDatePicker
                                id="from"
                                label="From"
                                maxDate={this.state.to}
                                value={this.state.from}
                                onChange={(date) => this.setState({from:date.toISOString().replace("T", " ").slice(0, 23)})}
                            />
                            </div>

                            <div style={{width:'180px',marginRight:'10px',marginLeft:'10px'}}>
                            <FilterDatePicker
                                maxDate={Date()}
                                id="to"
                                label="To"
                                disableFuture
                                value={this.state.to}
                                onChange={(date) => this.setState({to:date.toISOString().replace("T", " ").slice(0, 23)})}
                            />
                            </div>

                        <div style={{alignSelf:'center',margin:'10px',marginRight:'20px'}}>
                            <Button
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.saveFilter()
                                    this.getOrders()
                                }}
                            >Filter
                            </Button>
                        </div> 

                    </div>
                    </Card>
                </div>
                <Divider style={styles.divider}/>
                <div>

                <TableWithActions
                    columns = {columns}
                    data = {orders.completedOrders}
                />


            </div>
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='300px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            </div>
        )
    }
}

export default withSnackbar((CompletedOrders))