import React, {Component} from 'react'

//import material ui components
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import {KeyboardDatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers'

//date picker
import DateFnsUtils from '@date-io/date-fns';

class FilterField extends Component {

    render() {
        return (
           <div>
              <TextField
                disabled={this.props.disabled}
                id={this.props.id}
                label={this.props.label}
                style={{marginTop:'5px',marginBottom:'10px'}}
                margin="normal"
                size = "small"
                inputProps={{style: {fontSize: '12px'}}}
                onChange = {this.props.onChange}
                value={this.props.value}
          /> 
            </div>
        )
    }
}


export {FilterField}

class FilterDropDown extends Component {
    render() {
        return (
            <FormControl  size = "small" style={{float:'left',marginLeft:'15px',marginBottom:'10px',marginTop:'0px'}}>
            <Select
                labelId="demo-simple-select-helper-label"
                id={this.props.id}
                displayEmpty={true}
                defaultValue = {this.props.defaultValue}
                variant="standard"
                size="small"
                renderValue={this.props.renderValue}
                style={{fontSize:'12px',paddingLeft:"0px"}}
                onChange = {this.props.onChange}
                value={this.props.value}
                >
              
            {this.props.options.map((option, index) => {
                return(
                    <option 
                    style={{fontSize:'12px'}}
                        value={option.name} 
                        key = {index}
                    >{option.label}
                    </option>
                )
                    
            })}
                </Select>
            </FormControl>
        )
        
    }
}

export {FilterDropDown}

class FilterDropDownWithLabel extends Component {
    render() {
        return (
            <FormControl style={{width:'180px'}} disabled={this.props.disabled} >
                <InputLabel id="demo-simple-select-outlined-label">{this.props.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    autoWidth
                    style={{fontSize:'12px',paddingLeft:"0px"}}
                    displayEmpty={true}
                    defaultValue={this.props.defaultValue}
                >
                {this.props.options.map((option, index) => {
                return(
                    <MenuItem value={option[this.props.optionValueId]}>{option[this.props.optionLabelId]}</MenuItem>
                )
                    
            })}
                </Select>
      </FormControl>
        )
    }
}

export {FilterDropDownWithLabel}

class FilterDatePicker extends Component {
    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableFuture = {this.props.disableFuture}
                disableToolbar
                disablePast = {this.props.disablePast}
                maxDate={this.props.maxDate}
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id={this.props.id}
                label={this.props.label}
                value={this.props.value}
                onChange = {this.props.onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
        />
        </MuiPickersUtilsProvider>
        )
    }
}

export {FilterDatePicker}