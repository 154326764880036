import React, {Component} from 'react'
import {withSnackbar } from 'notistack'

import LoadingScreen from '../../common/LoadingScreen'
//import material-ui components
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

//Context
import {CategoriesContext} from './../../../context/categories/Context'
import {setCategoriesWithAllSubCategories} from './../../../context/categories/Actions'
import {fetchCategorieswithAllSubCategories} from '../../../repository/CategoriesRepository'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

import NewProviders from './NewProviders'
import ApprovedProviders from './ApprovedProviders'

const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'0px',
        marginBottom:'0px'
    }
}

class manageProviders extends Component {
    constructor(props){
        super(props)
        this.state = {
            tab:0,
            loading:{
                status:false,
                message:''
            },
        }
    }

    static contextType = CategoriesContext

    handleTabsChange = (event, newTab) =>{
        this.setState({tab:newTab})
    }


    //fetch categories with all sub categories
    getCategoriesWithAllSubCategories =async () => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting categories details'}})
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchCategorieswithAllSubCategories({},idToken).then((data) => {
                    if(data!=null){
                        dispatch(setCategoriesWithAllSubCategories(data))
                        this.setState({loading:{status:false,message:''}})
                    }
                }).catch(error => {
                    this.props.enqueueSnackbar("Import category failed "+error,{variant:'error'})
                    this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Import category failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }finally{
           // this.setState({loading:{status:false,message:''}})
        }
    }

    componentDidMount(){
        const {categories} = this.context
        if(categories.categoriesWithAllSubCategories.length==0){
            this.getCategoriesWithAllSubCategories()
        }
    }

    render(){
        const {categories} = this.context
        return(
            <div>
                <div style = {styles.headingContainer}>
                <h4> <span>Manage Providers</span></h4>
                </div>
                <div>
                <Paper>
                    <Tabs
                        value={this.state.tab}
                        onChange={this.handleTabsChange}
                        indicatorColor="primary"
                        textColor="primary"
                        disableRipple
                        style={{marginBottom:'0px',paddingBottom:'0px'}}
                    >
                        <Tab style={{fontSize:'16px', fontWeight:'bold', marginTop:'0px',paddingTop:'0px'}} label="Pending"/>
                        <Tab style={{fontSize:'16px', fontWeight:'bold'}} label="Approved"/>
                    </Tabs>
                    <Divider style={styles.divider}/>
                    <div hidden={this.state.tab!=0} index={0}>
                        <NewProviders categories = {categories.categoriesWithAllSubCategories}/>
                    </div>
                    <div hidden={this.state.tab!=1} index={1}>
                        <ApprovedProviders categories = {categories.categoriesWithAllSubCategories}/>
                    </div>

                    </Paper>
                </div>
                <div>
                
                </div>
                {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            </div>
        )
    }

}

function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

export default withSnackbar(manageProviders)