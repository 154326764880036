import React, {Component} from 'react';

//importing styles
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles'

//importing material-ui components
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import InfoIcon from '@material-ui/icons/Info'

//Firebase
import {signOutUser} from './../../service/AuthService'

//Context
import {AuthContext} from './../../context/auth/Context'
import {userLogout, clearUserRole} from './../../context/auth/Actions'

//Importing sidebar
import Sidebar from './../common/Sidebar'

import {generateNotifications} from '../../service/Notifications'
import {host} from '../../service/env'

const drawerWidth = 250;
var stompClient = null

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width:65,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    display :'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding : '0 8px',
    ...theme.mixins.toolbar
    },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logoutBtn:{
      marginLeft:'auto',
      marginRight:'40px'
  }
})


class AdminWrapper extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open:false,
            openNotifications:false,
            notifications:[],
            notificationsCount:5,
            anchor:null
        }
    }

    static contextType = AuthContext


    handleDrawerOpen = () => {
        this.setState({open: true})
        
    }
  
    handleDrawerClose = () => {
        this.setState({open : false})
    }

    handleNotificationsOpen = (e) => {
      this.setState({openNotifications:true})
      this.setState({anchor:e.target})
    }

    handleNotificationsClose = () => {
      this.setState({openNotifications:false})
      this.setState({notifications:[]})
      this.setState({anchor:null})
    }

    signOut = async() => {
      const { dispatch } = this.context
        try {
            await signOutUser().then(() => {
              dispatch(userLogout())
              dispatch(clearUserRole())
            })
        } catch (e) {
            console.log(e);
        }
    }
    connectWebSocket = () => {
      const Stomp = require('stompjs')

      var SockJS = require('sockjs-client')
      let HOST = host
      SockJS = new SockJS(HOST+'ws')

      stompClient = Stomp.over(SockJS);
  
      stompClient.connect({}, this.onConnect, this.onError);
    }

    onConnect = () => {
              stompClient.subscribe('/topic/notifications',this.onMessageReceived);
              stompClient.send("/app/subscribe", {}, JSON.stringify('admin'))
    }

    onError = (e) => {
      console.log(e)
    }

    onMessageReceived = (message) => {
      let notification = generateNotifications(message)
      let arr = this.state.notifications
      arr = arr.concat(notification)
      console.log(notification)
      this.setState({notifications:arr})
    }

    componentDidMount = () => {
      this.connectWebSocket()
    }

    render() {
        const {auth} =this.context
        const {classes} = this.props
        return (
            <div className={classes.root}>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: this.state.open,
                })}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: this.state.open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap>
                    Ecfinder
                  </Typography>
                  <Badge 
                      badgeContent={this.state.notifications.length} 
                      aria-controls="notification-menu" 
                      color="secondary" 
                      className = {classes.logoutBtn} 
                      onClick={e=>{this.handleNotificationsOpen(e)}}>
                    <NotificationsIcon size="large"/>
                  </Badge>

                  <Menu
                      id="notification-menu"
                      anchorEl={this.state.anchor}
                      keepMounted
                      open={this.state.openNotifications}
                      onClose={this.handleNotificationsClose}
                    >
                      <div style={{width:'300px',padding:'0px 10px 0px 10px'}}>Notifications</div>
                      {this.state.notifications.map((notification, index)=>{
                        return(
                          <MenuItem onClick={this.handleNotificationsClose}>
                            <div style={{width:'300px',padding:'0px 10px 0px 10px',borderBottom:'2px solid #b6b6b8'}}>
                              <div class="row" style={{padding:'0px 10px 0px 10px',fontSize:'14px'}}>
                                <InfoIcon
                                  color="primary"
                                  fontSize="14px"
                                />
                                <span style={{marginLeft:'5px'}}>{notification.body}</span>
                                </div>
                              <div style={{color:'grey',float:'right',fontSize:'12px'}}>
                                {notification.time}
                              </div>
                            </div>
                            </MenuItem>
                        )
                      })}
                      {this.state.notifications.length==0?
                        <MenuItem onClick={this.handleNotificationsClose}>
                        <div style={{width:'300px',padding:'0px 10px 0px 10px',borderBottom:'2px solid #b6b6b8'}}>
                          <div class="row" style={{padding:'0px 10px 0px 10px'}}>
                            No new notifications
                            </div>
                        </div>
                        </MenuItem>
                      :null
                    }
                    </Menu>

                  <Button
                            variant="contained"
                            startIcon={<ExitToAppIcon/>}
                            size = "small"
                            onClick = {() => {
                              this.signOut()
                            }}
                        >
                            Logout
                </Button>
                </Toolbar>
              </AppBar>
                
              
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: this.state.open,
                  [classes.drawerClose]: !this.state.open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: this.state.open,
                    [classes.drawerClose]: !this.state.open,
                  }),
                }}
                > <div className = {classes.toolbarIcon}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </div>
                    <Divider/>

                <Divider />
                <Sidebar/>
              </Drawer>
              
              <main className={classes.content}>
                <div className={classes.toolbar} />
                {this.props.children}
              </main>
              
            </div>
          )
    }
  
}


export default (withStyles(styles)(AdminWrapper))