
export const getFeeds = feeds => ({
    type: "GET_FEEDS",
    feeds
})

export const setFeedsFilter = filter => ({
    type: "SET_FEEDS_FILTER",
    filter
})

export const getSlides = slides => ({
    type: "GET_SLIDES",
    slides
})

export const getServiceCharge = serviceCharge => ({
    type: 'GET_SERVICE_CHARGE',
    serviceCharge
})