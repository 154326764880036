import PendingPayments from "../../component/pages/PendingPayments"

export const getPendingOrders = pendingOrders => ({
    type: "GET_PENDING_ORDERS",
    pendingOrders
})

export const getCompletedOrders = completedOrders => ({
    type: "GET_COMPLETED_ORDERS",
    completedOrders
})

export const getCancelledOrders = cancelledOrders => ({
    type: "GET_CANCELLED_ORDERS",
    cancelledOrders
})

export const setPendingOrdersFilter = pendingOrdersFilter => ({
    type: "SET_PENDING_ORDERS_FILTER",
    pendingOrdersFilter
})

export const setCompletedOrdersFilter = completedOrdersFilter => ({
    type: "SET_COMPLETED_ORDERS_FILTER",
    completedOrdersFilter
})

export const setCancelledOrdersFilter = cancelledOrdersFilter => ({
    type: "SET_CANCELLED_ORDERS_FILTER",
    cancelledOrdersFilter
})

export const setPendingPayments = pendingPayments => ({
    type: "SET_PENDING_PAYMENTS",
    pendingPayments
})

export const setPendingPaymentsFilter = filter =>({
    type: "SET_PENDING_PAYMENTS_FILTER",
    filter
})

export const setCompletedPayments = completedPayments => ({
    type: "SET_COMPLETED_PAYMENTS",
    completedPayments
})

export const setCompletedPaymentsFilter = filter =>({
    type: "SET_COMPLETED_PAYMENTS_FILTER",
    filter
})