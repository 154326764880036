import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'

import Field from '../../common/Field'
import {uploadImages,deleteImages, addItem} from '../../../repository/ItemsRepository'
import _ from 'lodash'
import LoadingScreen from '../../common/LoadingScreen'
import ConfirmationBox from '../../common/ConfirmationBox'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { Item } from '../../../models/Item'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

class Update extends Component {

    constructor(props){
        super(props)
        this.state={
            item:this.props.item,
            selectedCategory:this.props.selectedCategory,
            selectedSubCategories:[],
            deletedImages:[],
            addedImages:[],
            loading:{
                status:false,
                message:''
            },
            fileInput:'',
            confirm:{
                status:false,
                message:''
            },
            imageViewer:{
                images:'',
                status:false
            }
        }
        this.inputRef = React.createRef()
    }

    openImageViewer = (e) => {
        let arr = []
        let img = {src:e.target.src}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }

    selectCategory = (e) => {
        this.setState({item:{...this.state.item, categoryId:e.target.value}, subCategoryId:''})
        let index = _.findIndex(this.props.categories, {'categoryId':e.target.value})
        this.setState({selectedSubCategories:this.props.categories[index].subCategoryList})
    }

    selectSubCategory = (e) => {
        this.setState({item:{...this.state.item, subCategoryId:e.target.value}})
    }

    addImage = (e) => {
        let imageFile = {
            url : URL.createObjectURL(e.target.files[0]),
            file : e.target.files[0]
        }
        let arr = this.state.addedImages.concat(imageFile)
        this.setState({ addedImages:arr})
    }

    deleteNewImage = (e) => {
        let arr =  this.state.addedImages.filter(file => e.currentTarget.value!=file.url)
        this.setState({addedImages:arr})
    }

    deleteOldImage = (e) => {
        let imageList = this.state.item.images
        var imagesToDelete = _.remove(imageList, (n) => {
           return n.id == e.currentTarget.value
        });
        this.setState({deletedImages:imagesToDelete})
        this.setState({item:{...this.state.item, images:imageList}})
    }

    validate = () => {
        if(
            this.state.item.categoryId =='' ||
            this.state.item.subCategoryId =='' ||
            this.state.item.itemName =='' ||
            this.state.item.itemNameArabic =='' ||
            this.state.item.itemDescriptionArabic =='' ||
            this.state.item.unitPrice =='' ||
            this.state.item.unitType ==''||
            (this.state.item.images.length+this.state.addedImages.length==0||
                this.state.item.unitTypeArabic == ''||
                this.state.item.unitTypeArabic == ''
            )
        ){
            this.props.enqueueSnackbar("All fields are required!",{variant:'warning'})
            return false
        }else{
            if(isNaN(this.state.item.unitPrice)){
                this.props.enqueueSnackbar("Enter a valid amount for price",{variant:'warning'})
                return false
            }else{
                return true
            }
        }
    }

    addSearchTags = (itemName) => {
        let searchTags = itemName.toLowerCase().split(" ");
        return searchTags;
    }

    updateItem = async () => {
       if(this.validate()){
        let searchTags = this.addSearchTags(this.state.item.itemName)
            let updatedItem = new Item(
                this.state.item.itemId,
                this.state.item.itemNameArabic,
                this.state.item.itemDescriptionArabic,
                this.state.item.unitTypeArabic,
                this.state.item.itemName,
                searchTags,
                this.state.item.description,
                this.state.item.unitType,
                this.state.item.unitPrice,
                this.state.item.subCategoryId,
                this.state.item.images,
                this.state.item.createdAt,
                this.state.item.updatedAt
                )

            try{
                if(this.state.deletedImages.length!=0){
                    this.setState({loading:{status:true,message:'Deleting old images'}})
                    await deleteImages(this.state.deletedImages)
                }

                if(this.state.addedImages.length!=0){
                this.setState({loading:{status:true,message:'Uploading images'}})
                let uploadedImages = await uploadImages(this.state.addedImages)
                updatedItem['images']= await updatedItem['images'].concat(uploadedImages)
                this.props.enqueueSnackbar("Images are uploaded successfully",{variant:"success"})
                }

                this.setState({loading:{status:true,message:'Updating item'}})
                await FirebaseApp.auth().currentUser.getIdToken(true).then(async (idToken) => {
                        await addItem(updatedItem,idToken)
                        this.props.enqueueSnackbar("Item was updated successfully",{variant:'success'})
                    })
                this.props.handleCloseWithRefresh()
            }catch(error){
                this.props.enqueueSnackbar("Update item was failed"+error,{variant:'error'})
            }finally{
                this.setState({loading:{status:false,message:''}})
            }

        }
    }


    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'850px',position:'relative',paddingBottom:'20px'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Update Item</h4>
                        <br></br>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="d-flex flex-direction-row">
                                    <div style={{marginRight:'20px',marginLeft:'5px'}}>
                                    <FormControl variant="outlined" style={{width:'180px'}} disabled={true} >
                                        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.selectedCategory}
                                            onChange={(e)=>{this.selectCategory(e)}}
                                            autoWidth
                                            style={{fontSize:'12px',paddingLeft:"0px"}}
                                            displayEmpty={true}
                                            defaultValue={this.props.defaultValue}
                                            label="Category"
                                        >
                                            {this.props.categories.map((option, index) => {
                                            return(
                                                <MenuItem value={option['categoryId']}>{option['categoryName']}</MenuItem>
                                            )

                                        })}
                                            </Select>
                                    </FormControl>
                                    </div>
                                    <div style={{marginRight:'20px',marginLeft:'5px'}}>
                                    <FormControl variant="outlined" style={{width:'180px'}} disabled={true} >
                                        <InputLabel id="demo-simple-select-outlined-label">Sub Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.item.subCategoryId}
                                            onChange={(e)=> {this.selectSubCategory(e)}}
                                            autoWidth
                                            style={{fontSize:'12px'}}
                                            displayEmpty={true}
                                            defaultValue={this.props.defaultValue}
                                            label="Sub Category"
                                        >
                                            {this.props.selectedSubCategories.map((option, index) => {
                                            return(
                                                <MenuItem value={option['subCategoryId']}>{option['subCategoryName']}</MenuItem>
                                            )

                                        })}
                                            </Select>
                                    </FormControl>
                                    </div>
                                </div>
                                <Field
                                    type='text'
                                    id='name'
                                    placeholder='Item Name'
                                    size='small'
                                    value = {this.state.item.itemName}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,itemName:e.target.value}})}}
                                    autoFocus={false}
                                    required={true}
                                />
                                <Field
                                    type='text'
                                    id='itemNameArabic'
                                    placeholder='Item Arabic Name'
                                    size='small'
                                    value = {this.state.item.itemNameArabic}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,itemNameArabic:e.target.value}})}}
                                    autoFocus={false}
                                    required={true}
                                />
                                <Field
                                    type='text'
                                    id='description'
                                    placeholder='Item Description'
                                    size='small'
                                    value = {this.state.item.description}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,description:e.target.value}})}}
                                    autoFocus={false}
                                    multiline = {true}
                                    rows = {4}
                                />
                                <Field
                                    type='text'
                                    id='itemDescriptionArabic'
                                    placeholder='Item Description Arabic'
                                    size='small'
                                    value = {this.state.item.itemDescriptionArabic}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,itemDescriptionArabic:e.target.value}})}}
                                    autoFocus={false}
                                    multiline = {true}
                                    rows = {4}
                                />
                               
                            </div>
                            <div className='col-lg-6'>
                            <Field
                                    type='text'
                                    id='unitType'
                                    placeholder='Unit Type'
                                    value = {this.state.item.unitType}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,unitType:e.target.value}})}}
                                    size='small'
                                    autoFocus={false}
                                    required={true}
                                />
                                <Field
                                    type='text'
                                    id='unitTypeArabic'
                                    placeholder='Unit Type Arabic'
                                    value = {this.state.item.unitTypeArabic}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,unitTypeArabic:e.target.value}})}}
                                    size='small'
                                    autoFocus={false}
                                    required={true}
                                />
                                <Field
                                    type='text'
                                    id='unitPrice'
                                    placeholder='Unit Price'
                                    value = {this.state.item.unitPrice}
                                    onChange = {(e)=>{this.setState({item:{...this.state.item,unitPrice:e.target.value}})}}
                                    size='small'
                                    autoFocus={false}
                                    required={true}
                                />
                            <div className="d-flex flex-direction-row" style={{width:'100%',marginBottom:'10px'}}>
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon/>}
                                    size = "small"
                                    onClick = {(e) => {
                                        this.inputRef.current.click()
                                    }}
                                    color="primary"
                                    style={{marginLeft:'auto'}}
                                >Add Image
                            </Button>
                            <input
                                hidden id="fileUpload"
                                type="file"
                                value={this.state.fileInput}
                                ref = {this.inputRef}
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange = {
                                    (e)=> { this.addImage(e)}
                                }
                            />
                            </div>
                            <div style={{ height:'200px',overflowY:'scroll',overflowX:'hidden',width:'100%'}}>
                                {this.state.addedImages.map((image, i) => {
                                    return (
                                        <Card style={{width:'360px',marginBottom:'10px'}}>
                                            <img
                                                style={{width:'280px',height:'100px',objectFit:'cover'}}
                                                src={image.url}
                                                onClick = {(e)=>this.openImageViewer(e)}
                                                alt={''}/>
                                            <IconButton
                                                id={image.url}
                                                aria-label="delete"
                                                style={{marginLeft:'15px'}}
                                                value = {image.url}
                                                onClick = {(e) => {this.deleteNewImage(e)}}
                                                >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Card>
                                    )
                                })}
                                {this.state.item.images.map((image, i) => {
                                    return (
                                        <Card style={{width:'360px',marginBottom:'10px'}}>
                                            <img
                                                style={{width:'280px',height:'100px',objectFit:'cover'}}
                                                src={image.url}
                                                onClick = {(e)=>this.openImageViewer(e)}
                                                alt={''}/>
                                            <IconButton
                                                id={image.id}
                                                aria-label="delete"
                                                style={{marginLeft:'15px'}}
                                                value = {image.id}
                                                onClick = {(e) => {this.deleteOldImage(e)}}
                                                >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Card>
                                    )
                                })}
                                {this.state.addedImages.length==0 && this.state.item.images.length==0?
                                     <div style={{width:'100%',height:'200px',display:'flex',flexDirection:'column'}}>
                                         <div style={{margin:'auto'}}>No images are added</div>
                                         </div>
                                    :null
                                }
                            </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='d-flex flex-direction-row float-right'>
                        <Button
                                variant="contained"
                                startIcon={<CancelIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                style={{marginRight:'10px'}}
                                color="secondary"
                            >Cancel
                        </Button>

                        <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setState({confirm:{message:'Confirm',status:true}})
                                }}
                                color="primary"
                            >Update Item
                        </Button>
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null
                    }
                    {this.state.confirm.status?
                    <   ConfirmationBox
                        open={this.state.confirm.status}
                        message = {this.state.confirm.message}
                        handleClose = {(e)=>{this.setState({confirm:{...this.state.confirm,status:false}})}}
                        handleCloseConfirmed = {
                            (e)=>{
                                this.setState({confirm:{...this.state.confirm,status:false}})
                                this.updateItem()
                            }

                        }
                    />
                    :null
                    }
                    {this.state.imageViewer.status?
                            <ImageViewer
                                imgs={ this.state.imageViewer.images }
                                currImg={0}
                                isOpen = {this.state.imageViewer.status}
                                onClose={ this.closeImageViewer }
                            />
                            : null
                    }
                    </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default withSnackbar(Update)
