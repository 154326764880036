
const OrderReducer = (state, action) => {
    switch (action.type){
        case 'GET_PENDING_ORDERS':
            return {
                ...state,
                pendingOrders:action.pendingOrders
            }
        case 'SET_PENDING_ORDERS_FILTER':
            return {
                ...state,
                pendingOrdersFilter:action.pendingOrdersFilter
            }

        case 'GET_COMPLETED_ORDERS':
            return {
                ...state,
                completedOrders:action.completedOrders
            }
        case 'SET_COMPLETED_ORDERS_FILTER':
                return {
                    ...state,
                    completedOrdersFilter:action.completedOrdersFilter
                }
        case 'GET_CANCELLED_ORDERS':
            return {
                ...state,
                cancelledOrders: action.cancelledOrders
            }
        case 'SET_CANCELLED_ORDERS_FILTER':
                return {
                    ...state,
                    cancelledOrdersFilter:action.cancelledOrdersFilter
            }
        case 'SET_PENDING_PAYMENTS':
            return{
                ...state,
                pendingPayments : action.pendingPayments
            }
        case 'SET_PENDING_PAYMENTS_FILTER':
            return{
                ...state,
                pendingPaymentsFilter : action.filter
            }
        case 'SET_COMPLETED_PAYMENTS':
            return{
                ...state,
                completedPayments : action.completedPayments
            }
        case 'SET_COMPLETED_PAYMENTS_FILTER':
            return{
                ...state,
                completedPaymentsFilter : action.filter
            }
        default:
            return(
                state
            )
    }
}

export default OrderReducer;