import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'

import Field from '../../common/Field'
import _ from 'lodash'
import LoadingScreen from '../../common/LoadingScreen'
import ConfirmationBox from '../../common/ConfirmationBox'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'


//firebase
import FirebaseApp from '../../../FirebaseSetup'
import {approveProvider} from '../../../repository/UsersRepository'

class ProviderDetails extends Component {

    constructor(props){
        super(props)
        this.state={
            loading:{
                status:false,
                message:''
            },
            confirm:{
                status:false,
                message:''
            },
            imageViewer:{
                images:'',
                status:false
            },
            categoryNames:[]
        }
        this.inputRef = React.createRef()
    }

    openImageViewer = (e) => {
        let arr = []
        let img = {src:e.target.src}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }

    showCategoryName = () => {
        let provider = this.props.provider
        let categories = this.props.categories
        let categoryNames = []
        for(let x=0;x<categories.length;x++){
            if(categories[x].categoryId==provider.services[0]){
                categoryNames.push(categories[x].categoryName)
            }else if(categories[x].categoryId==provider.services[1]){
                categoryNames.push(categories[x].categoryName);
            }
        }
        this.setState({categoryNames:categoryNames})
    }

    approveProvider = async () => {
        let providerId = this.props.provider.providerId
        this.setState({loading:{status:true,message:'Approving selected provider'}})

        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                approveProvider(providerId,idToken).then((data) => {
                        this.setState({loading:{status:false,message:''}})
                        this.props.enqueueSnackbar("Provider was approved successfully ",{variant:'success'})
                        this.props.handleCloseWithRefresh()
                }).catch((error)=>{
                    this.props.enqueueSnackbar("Approve provider failed "+error,{variant:'error'})
                   this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Approve provider failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }

    }

    componentDidMount = () => {
        if(this.state.categoryNames.length==0){
            this.showCategoryName()
        }
    }
    
    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'850px',position:'relative',paddingBottom:'20px',height:'90vh',maxHeight:'700px',overflowY:'auto'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Provider Details</h4>
                        <br></br>
                        <div className='row'>
                            <div className='col-lg-6'>
                            <Field
                                    disabled={true}
                                    type='text'
                                    id='name'
                                    placeholder='Provider ID'
                                    size='small'
                                    value = {this.props.provider.providerId}
                                    autoFocus={false}
                                />
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='name'
                                    placeholder='Name'
                                    size='small'
                                    value = {this.props.provider.firstName+" "+this.props.provider.lastName}
                                    autoFocus={false}
                                />
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='username'
                                    placeholder='Username'
                                    size='small'
                                    value = {this.props.provider.userName}
                                    autoFocus={false}
                                />
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='phoneNumber'
                                    placeholder='Phone Number'
                                    size='small'
                                    value = {this.props.provider.phoneNumber}
                                    autoFocus={false}
                                />
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='city'
                                    placeholder='City'
                                    size='small'
                                    value = {this.props.provider.city}
                                    autoFocus={false}
                                />
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='district'
                                    placeholder='District'
                                    size='small'
                                    value = {this.props.provider.district}
                                    autoFocus={false}
                                />

                                <Field
                                    disabled={true}
                                    type='text'
                                    id='email'
                                    placeholder='Address'
                                    size='small'
                                    value = {this.props.provider.address}
                                    autoFocus={false}
                                />
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='email'
                                    placeholder='Email Address'
                                    size='small'
                                    value = {this.props.provider.email}
                                    autoFocus={false}
                                />
                                {this.props.provider.vehicleType!=""?
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='vehicleTyoe'
                                    placeholder='Vehicle Type'
                                    size='small'
                                    value = {this.props.provider.vehicleType}
                                    autoFocus={false}
                                />
                                :null    
                            }
                             {this.props.provider.vehicleNumber!=""?
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='vehicleNumber'
                                    placeholder='Vehicle Number'
                                    size='small'
                                    value = {this.props.provider.vehicleNumber}
                                    autoFocus={false}
                                />
                                :null    
                            }
                            </div>
                            <div className='col-lg-6'>
                            <Field
                                    type='text'
                                    id='services'
                                    placeholder='Services'
                                    size='small'
                                    disabled={true}
                                    value = {this.state.categoryNames[0]+"\n"+this.state.categoryNames[1]}
                                    multiline = {true}
                                    rows = {2}
                                />
                            <Field
                                    disabled={true}
                                    type='text'
                                    id='nationalIdNumber'
                                    placeholder='National ID Number'
                                    size='small'
                                    value = {this.props.provider.nationalIdNumber}
                                    autoFocus={false}
                                />
                            <Field
                                    disabled={true}
                                    type='text'
                                    id='nationality'
                                    placeholder='Nationality'
                                    size='small'
                                    value = {this.props.provider.nationality}
                                    autoFocus={false}
                                />
                            <Field
                                    disabled={true}
                                    type='text'
                                    id='bankName'
                                    placeholder='Bank'
                                    size='small'
                                    value = {this.props.provider.bankName}
                                    autoFocus={false}
                                />
                            <Field
                                    disabled={true}
                                    type='text'
                                    id='iban'
                                    placeholder='IBAN Number'
                                    size='small'
                                    value = {this.props.provider.ibanNumber}
                                    autoFocus={false}
                                />
                                <Field
                                    disabled={true}
                                    type='text'
                                    id='accountHolderName'
                                    placeholder='Account Holder Name'
                                    size='small'
                                    value = {this.props.provider.accountHolderName}
                                    autoFocus={false}
                                />
                            <div style={{ height:'100px',overflowY:'auto',overflowX:'hidden',width:'100%'}}>
                                {this.props.provider.documents.map((image, i) => {
                                    return (
                                        <Card style={{width:'100%'}}>
                                            <img 
                                            style={{width:'100%',height:'100px',objectFit:'cover'}} 
                                            onClick = {(e)=>this.openImageViewer(e)}
                                            src={image.url} alt={''}/>
                                        </Card>
                                    )
                                })}
                                {this.props.provider.documents.length==0?
                                     <div style={{width:'100%',height:'100px',display:'flex',flexDirection:'column'}}>
                                         <div style={{margin:'auto'}}>No documents are added</div>
                                         </div>
                                    :null
                                }
                            </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='d-flex flex-direction-row float-right'>
                        <Button
                                variant="contained"
                                startIcon={<CancelIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                style={{marginRight:'10px'}}
                                color="secondary"
                            >Back
                        </Button>
                        {this.props.pendingProvider?
                            <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setState({confirm:{message:'Confirm',status:true}})
                                }}
                                color="primary"
                            >Approve
                        </Button> 
                        :null   
                    }
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
                    {this.state.confirm.status?
                    <   ConfirmationBox
                        open={this.state.confirm.status}
                        message = {this.state.confirm.message}
                        handleClose = {(e)=>{this.setState({confirm:{...this.state.confirm,status:false}})}}
                        handleCloseConfirmed = {
                            (e)=>{
                                this.setState({confirm:{...this.state.confirm,status:false}})
                                this.approveProvider()

                            }
                            
                        }
                    />
                    :null    
                    }
                 {this.state.imageViewer.status?
                <ImageViewer
                    imgs={ this.state.imageViewer.images }
                    currImg={0}
                    isOpen = {this.state.imageViewer.status}
                    onClose={ this.closeImageViewer }
                />
                : null    
        }
                    </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default withSnackbar(ProviderDetails)