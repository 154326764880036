import React, {createContext, useReducer} from 'react'
import AuthReducer from './Reducers'

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const [auth, dispatch] = useReducer(AuthReducer, {
            currentUser:null,
            currentUserRole:'',
            notifications:[]

    })


    return (
        <AuthContext.Provider value={{auth, dispatch}}>
           {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
