
const ConfigurationsReducer = (state, action) => {
    switch (action.type){
        case 'GET_FEEDS':
            return {
                ...state,
                feeds : action.feeds
            }
        case 'SET_FEEDS_FILTER':
            return{
                ...state,
                feedsFilter : action.filter
            }
        case 'GET_SLIDES':
            return{
                ...state,
                slides : action.slides
            }
        case 'GET_SERVICE_CHARGE':
            return{
                ...state,
                serviceCharge : action.serviceCharge
            }
        default:
            return(
                state
            )
    }
}

export default ConfigurationsReducer;