import {API} from '../service/RESTService'
import firebase from '../FirebaseSetup'
import UUIDGenerator from '../service/UUIDGenerator'

const SLIDES_ENDPOINT = 'api/v1/configurations/slides'
const FEEDS_ENDPOINT = 'api/v1/configurations/feeds'
const SERVICE_CHARGE_ENDPOINT = "api/v1/configurations/serviceCharge"
const EDITOR_ENDPOINT = "api/v1/configurations/editor"

var storage = firebase.storage()
var slidesRef = storage.ref('configurations/slides')
var feedsRef = storage.ref('configurations/feeds')

export const fetchSlides = (idToken) => {
    return API.GET(SLIDES_ENDPOINT,idToken,{})
}

export const addSlides = (idToken,slides) => {
    return API.POST(SLIDES_ENDPOINT,idToken,slides)
}

export const fetchFeeds = (idToken, feeds) => {
    return API.GET(FEEDS_ENDPOINT,idToken,{})
}

export const addFeeds = (idToken, feeds) => {
    let feedsJSON = JSON.stringify(feeds)
    return API.POST(FEEDS_ENDPOINT, idToken, feedsJSON)
}

export const fetchServiceCharge = (idToken) => {
    return API.GET(SERVICE_CHARGE_ENDPOINT,idToken,{})
}

export const setServiceCharge = (idToken, param) => {
    return API.POST(SERVICE_CHARGE_ENDPOINT,idToken,param)
}

export const createEditor = (idToken, editor) => {
    return API.POST(EDITOR_ENDPOINT,idToken, editor)
}

export const uploadImages = async (images,type) => {
    let storageRef
    switch(type){
        case 'slides':
            storageRef = slidesRef
            break
        case 'feeds' :
            storageRef = feedsRef
            break
    }
    try{ 
        let uploadedImages = []
         for(let x=0; x<images.length;x++){
             let image = images[x]
             let imageId = UUIDGenerator().toString()
             let fileExtension = image.file.name.replace(/^.*\./, '');
             let imageRef = storageRef.child(imageId+'.'+fileExtension) 
             const res = await imageRef.put(image.file)
             const url = await res.ref.getDownloadURL()
             const imageData = {
                 id: imageId+'.'+fileExtension,
                 url:url
             }
             await uploadedImages.push(imageData)
         }
         return uploadedImages
     }catch(error){
         throw error
     }
 }

export const deleteImages =async (images, type) => {
    let storageRef
    switch(type){
        case 'slides':
            storageRef = slidesRef
            break
        case 'feeds' :
            storageRef = feedsRef
            break
    }
    try{for(let x=0; x<images.length;x++){
        let image = images[x]
        let imageRef = storageRef.child(image.id)
        await imageRef.delete()
        }
    }catch(error){
        throw error
    }
}