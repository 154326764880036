import React, {Component} from 'react'
import {withSnackbar } from 'notistack'

import LoadingScreen from '../../common/LoadingScreen'
import UpdateCategory from './UpdateCategory'
import AddCategory from './AddCategory'
import DeleteCategory from './DeleteCategory'
import ManageSubCategories from './ManageSubCategories'

//material-ui components
import Divider from '@material-ui/core/Divider'
import {TableWithActions} from '../../common/TableView'
import {FilterField,FilterDropDownWithLabel} from '../../common/FilterField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FilterListIcon from '@material-ui/icons/FilterList'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import ClassIcon from '@material-ui/icons/Class'

//styling
import {withStyles} from '@material-ui/core/styles'

//import context
import {CategoriesContext} from '../../../context/categories/Context'
import {setItems,setCategoriesWithAllSubCategories,setItemsFilter,selectCategory,setCategoryFilter} from '../../../context/categories/Actions'


//items repository
import {fetchCategorieswithAllSubCategories} from '../../../repository/CategoriesRepository'
import {fetchItems} from '../../../repository/ItemsRepository'

//firebase
import FirebaseApp from '../../../FirebaseSetup'

const columns = [
        {title : 'Category Name',field:'categoryName'},
        {title : 'Description', field:'description'},
        {title : 'Created By', field:'createdBy'},
        {title : 'Updated By', field:'updatedBy'}
]

const styles = {
    headingContainer : {
        display:'flex',
        flexDirection: 'row'
    },
    filterContainer: {

        paddingTop:'0',
    },
    divider:{
        marginTop:'10px',
        marginBottom:'10px'
    }
}

const newStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      selectOptions:{
          color:'green',
          backgroundColor:'yellow'
      }
})

class ManageCategories extends Component{

        constructor(props) {
            super(props)

            this.state = {
                    category:{
                        data:'',
                        update:false,
                        delete:false,
                        add:false,
                        subCategories:false
                    },
                    categoryList:[],
                    filter:{
                        categoryName:''
                    },
                    loading:{
                        status:false,
                        message:''
                    },
                    categoriesWithAlSubCategories:[]
                    
            }
        }
    static contextType = CategoriesContext

    //fetch categories with all sub categories
    getCategoriesWithAllSubCategories =async () => {
        const {dispatch} = this.context
        this.setState({loading:{status:true,message:'Getting categories details'}})
        try{
            await FirebaseApp.auth().currentUser.getIdToken(true).then((idToken) => {
                fetchCategorieswithAllSubCategories({},idToken).then((data) => {
                    if(data!=null){
                        dispatch(setCategoriesWithAllSubCategories(data))
                        this.setState({loading:{status:false,message:''}})
                    }
                }).catch(error => {
                    this.props.enqueueSnackbar("Import category failed "+error,{variant:'error'})
                    this.setState({loading:{status:false,message:''}})
                })
              })
        }catch(error){
            this.props.enqueueSnackbar("Import category failed "+error,{variant:'error'})
            this.setState({loading:{status:false,message:''}})
        }finally{
           // this.setState({loading:{status:false,message:''}})
        }
    }

    saveFilter = () => {
        const {dispatch} = this.context
        dispatch(setCategoryFilter(this.state.filter))
    }

    setFilter = () => {
        const {categories} = this.context
        this.setState({filter:categories.categoryFilter})
    }

    handleInput =   (e)  =>  {
        this.setState({...this.state, filter:{categoryName:e.target.value}})
    }

    componentDidMount(){
        const {categories} = this.context
        this.setFilter()
        if(categories.categoriesWithAllSubCategories.length===0){
            this.getCategoriesWithAllSubCategories()
        }
    }

    componentWillUnmount(){
        this.saveFilter()
    }

    render(){
        const {classes} = this.props
        const {categories} = this.context
        return(
            <div  style={{position:'relative'}}>
                 <div style = {styles.headingContainer}>
                <h4> <span>Manage Categories</span></h4>
                <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        style={{marginLeft:'auto'}}
                        startIcon={<AddIcon/>}
                        onClick ={(e) => {this.setState({...this.state, category:{...this.state.category, add:true}})}}
                    >
                        Add Category
                    </Button>
                </div>
                <br></br>
                <div  className ="d-flex flex-row">
                    <Card>
                    <div className="w-100 d-flex flex-row">
                        <div style={{width:'180px',marginTop:'10px',marginRight:'15px'}}>
                                    <FilterField 
                                        disabled ={false}
                                        id = "category"
                                        label = "Category Name"
                                        value = {this.state.filter.categoryName}
                                        onChange = { e => {this.handleInput(e)}}
                                    />
                        </div>
                         
                        <div style={{alignSelf:'center',marginTop:'18px',marginRight:'15px'}}>
                            <Button
                                disabled ={false}
                                variant="contained"
                                startIcon={<FilterListIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                   this.getCategoriesWithAllSubCategories()
                                    
                                }}
                            >Refresh
                            </Button>
                        </div> 
                    </div>
                    </Card>
                    
                </div>
                <Divider style={styles.divider}/>
                <div style={{position:'relative'}}>

                {this.state.filter.categoryName==''?
                    <TableWithActions
                        columns = {columns}
                        data = {categories.categoriesWithAllSubCategories}
                        actions = {[
                            {
                                icon: Edit,
                                tooltip: 'Update',
                                onClick: (e,rowData)=>{this.setState({category:{...this.category, data:rowData, update:true}})}
                            },
                            {
                                icon: ClassIcon,
                                tooltip: 'Sub Categories',
                                onClick: (e,rowData)=>{this.setState({category:{...this.category, data:rowData, subCategories:true}})}
                                },
                            {
                                icon: DeleteOutline,
                                tooltip: 'Remove',
                                onClick: (e,rowData)=>{this.setState({category:{...this.category, data:rowData, delete:true}})}
                            },
                        ]}
                    />
                :
                    <TableWithActions
                        columns = {columns}
                        data = {categories.categoriesWithAllSubCategories.filter(category => {return category.categoryName===this.state.filter.categoryName})}
                        actions = {[
                            {
                                icon: Edit,
                                tooltip: 'Update',
                                onClick: (e,rowData)=>{this.setState({category:{...this.category, data:rowData, update:true}})}
                            },
                            {
                                icon: ClassIcon,
                                tooltip: 'Sub Categories',
                                onClick: (e,rowData)=>{this.setState({category:{...this.category, data:rowData, subCategories:true}})}
                                },
                            {
                                icon: DeleteOutline,
                                tooltip: 'Remove',
                                onClick: (e,rowData)=>{this.setState({category:{...this.category, data:rowData, delete:true}})}
                            },
                        ]}
                    />
                }
            </div>
            {this.state.loading.status?
                    <LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
            {this.state.category.update?
            <UpdateCategory
                open={this.state.category.update}
                category={this.state.category.data}
                handleClose={(e)=>{
                    this.setState({category:{...this.state.category, update:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({category:{...this.state.category, update:false}})
                    this.getCategoriesWithAllSubCategories(this.state)
                }}
            />
            :null
            }
            {this.state.category.add?
            <AddCategory
                open={this.state.category.add}
                categories = {categories.categoriesWithAllSubCategories}
                handleClose={(e)=>{
                    this.setState({category:{...this.state.category, add:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({category:{...this.state.category, add:false}})
                    this.getCategoriesWithAllSubCategories()
                }}
            />
            :null    
            }
            {this.state.category.delete?
            <DeleteCategory
                open={this.state.category.delete}
                item={this.state.category.data}
                category = {this.state.category.data}
                handleClose={(e)=>{
                    this.setState({category:{...this.state.category, delete:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({category:{...this.state.category, delete:false}})
                    this.getCategoriesWithAllSubCategories(this.state)
                }}
            />
            :null    
            }
            {this.state.category.subCategories?
            <ManageSubCategories
                open={this.state.category.subCategories}
                category={this.state.category.data}
                subCategories = {categories.categoriesWithAlSubCategories}
                handleClose={(e)=>{
                    this.setState({category:{...this.state.category, subCategories:false}})
                }}
                handleCloseWithRefresh={(e)=>{
                    this.setState({category:{...this.state.category, subCategories:false}})
                    this.getCategoriesWithAllSubCategories(this.state)
                }}
            />
            :null
            }
            </div>
        )
    }
}

export default withSnackbar((withStyles(newStyles)(ManageCategories)))

