
export const generateNotifications = (message) => {
    let notificationBody
    switch(message.body){
        case 'NEW_ORDER':
            notificationBody = 'New orders are recieved'
        default:
            //notificationBody = message.body
    }

    let time = new Date()
    let timeString = time.getHours()+':'
    if(time.getMinutes()<10){
      timeString = timeString+'0'
    }
    timeString = timeString+time.getMinutes()
    let notification = {
      body:notificationBody,
      time:timeString
    }

    return notification
}