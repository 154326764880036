import React, {createContext, useReducer} from 'react'
import ConfigurationsReducer from './Reducers'

export const ConfigurationsContext = createContext();

const ConfigurationsContextProvider = (props) => {
    const [configurations, dispatch] = useReducer(ConfigurationsReducer, {
            feeds:'',
            feedsFilter:{
                name:'',
                id:''
            },
            slides:[],
            serviceCharge:''

    })


    return (
        <ConfigurationsContext.Provider value={{configurations, dispatch}}>
           {props.children}
        </ConfigurationsContext.Provider>
    );
}

export default ConfigurationsContextProvider;
