import React, {Component} from 'react'

import { withRouter} from 'react-router-dom'
import LoadingScreen from '../common/LoadingScreen'
import {withSnackbar } from 'notistack'

//from material ui
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Field from './../common/Field'
import {withStyles} from '@material-ui/core/styles'

//text validation
import {withFormik} from 'formik'
import * as Yup from 'yup'

//Firebase
import {signInUser, signOutUser} from './../../service/AuthService'
import FirebaseApp from "../../FirebaseSetup"

//Context
import {AuthContext} from './../../context/auth/Context'
import {userLogin,setUserRole, userLogout} from './../../context/auth/Actions'

//Logo
import logo from './../assets/logo.png'

const fields = [
    {name:"email", type: "email" , placeholder:"Email", width:1, required:true},
    {name:"password", type: "password" , placeholder:"Password" , width:1, required:true},
]
const styles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', 
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    textField: {
        width:'100%'
    },
    logo:{
        width:'200px',
        height:'auto'
    }
  })
  

class Login extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading:false,
            testing:true
        }
      }

    static contextType = AuthContext
    
    signIn = async (values) => {
        const { auth, dispatch } = this.context
        
        try {
            this.setState({loading:true})
            await signInUser(values.email, values.password,values.checked)
                .then(
                   async currentUser => {
                   await FirebaseApp.auth().currentUser.getIdTokenResult()
                   .then(async (idTokenResult) => {
                      if (idTokenResult.claims.role =="admin") {
                          dispatch(setUserRole("admin"))
                          dispatch(userLogin(currentUser))
                          this.props.history.push("/")
                      } else if(idTokenResult.claims.role =="editor"){
                        dispatch(setUserRole("editor"))
                        dispatch(userLogin(currentUser))
                        this.props.history.push("/")
                      } else {
                          alert("You tried to login using unauthorized user account. Please try again")
                          dispatch(userLogout())
                          await signOutUser()
                          this.setState({loading:false})
                      }
                   })
                   .catch((error) => {
                     console.log(error);
                   })
                   
            })
        } catch (e) {
            this.setState({loading:false})
            alert(e.message)
            console.log(e)
        }
    }

    componentWillUnmount(){
        this.setState({loading:false})

    }

    render(){
        const {classes} = this.props
        const { auth, dispatch } = this.context
        this.dispatch = dispatch
       //console.log(this.context)
        return (
            <div className="jumbotron container bg-light" style={{height:'100vh'}}>
                <div className="d-flex justify-content-center">
                    <div className="col-lg-5" style={{position:'relative'}}>
                        <img src={logo} className={classes.logo}></img>
                    <Typography component="h1" variant="h5">
                        Ecfinder - Admin
                    </Typography>
                    <form className={classes.form} noValidate onSubmit = { e => {
                        e.preventDefault()
                        this.signIn(this.props.values)
                    }}>
                        {fields.map((f, i) => {
                            return(
                                <Field 
                                    
                                    key={i}
                                    {...f}
                                    type = {f.type}
                                    value = {this.props.values[f.name]}
                                    onChange = {this.props.handleChange}
                                    onBlur = {this.props.handleBlur}
                                    touched = {(this.props.touched[f.name])}
                                    errors = {this.props.errors[f.name]}
                                    autoFocus = {true}
                                    color = "secondary"
                                    
                                />
                            )
                        })}
                        <div className="w-100">
                            <FormControlLabel
                                control={
                                <Checkbox 
                                    onChange = {this.props.handleChange}
                                    name="checked" 
                                    color="primary" 
                                />}
                                label="Keep me logged in"
                                labelPlacement="start"
                                style={{margin:'0px'}}
                                
                            />
                            <br></br>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{margin:'0px',width:'200px'}}
                            >
                                LOGIN
                            </Button>
                        </div>
                        </form>
                        {this.state.loading?
                            <LoadingScreen
                            width={'100%'}
                            height={'100%'}
                            translateY ={'150px'}
                        />
                           :null
                    }
                    </div>
                </div>
            </div>
        )
    }
}


export default withSnackbar((withFormik({
    mapPropsToValues : () => ({
        email : '',
        password : '',
        checked:false
    }),
    validationSchema: Yup.object().shape({
        email : Yup.string().email('Email is invalid.').required('You need to login with email address.'),
        password: Yup.string().required('You need to enter your password.'),
        checked: Yup.boolean()
    }),
    handleSubmit:(values) =>{
       
       
    }
})(withStyles(styles)(withRouter(Login)))))