import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'

import Field from '../../common/Field'
import {uploadImages,deleteImages, addSubCategory} from '../../../repository/CategoriesRepository'
import _ from 'lodash'
import LoadingScreen from '../../common/LoadingScreen'
import { SubCategory } from '../../../models/SubCategory'
import ConfirmationBox from '../../common/ConfirmationBox'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'


//firebase
import FirebaseApp from '../../../FirebaseSetup'

class AddSubCategory extends Component {

    constructor(props){
        super(props)
        this.state={
            category:this.props.category,
            subCategory:{
                subCategoryId:'',
                subCategoryName:'',
                subCategoryArabicName:'',
                images:[]
            },
            addedImages:[],
            categoryId:this.props.categoryId,
            selectedSubCategories:[],
            fileInput:'',
            loading:{
                status:false,
                message:''
            },
            confirm:{
                status:false,
                message:''
            },
            imageViewer:{
                images:'',
                status:false
            }
        }
        this.inputRef = React.createRef()
    }

    openImageViewer = (e) => {
        let arr = []
        let img = {src:e.target.src}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }

    selectCategory = (e) => {
        this.setState({item:{...this.state.item, categoryId:e.target.value}, subCategoryId:''})
        let index = _.findIndex(this.props.categories, {'categoryId':e.target.value})
        this.setState({selectedSubCategories:this.props.categories[index].subCategoryList})
    }

    selectSubCategory = (e) => {
        this.setState({item:{...this.state.item, subCategoryId:e.target.value}})
    }


    addImage = (e) => {
        let imageFile = {
            url : URL.createObjectURL(e.target.files[0]),
            file : e.target.files[0]
        }
        let arr = this.state.addedImages.concat(imageFile)
        this.setState({ addedImages:arr})
    }

    deleteImage = (e) => {
        let arr =  this.state.addedImages.filter(file => e.currentTarget.value!=file.url)
        this.setState({addedImages:arr})
    }

    validate = () => {
        if(
            this.state.subCategory.subCategoryName =='' ||
            this.state.subCategory.subCategoryArabicName ==''||
            this.state.addedImages.length==0
        ){
            this.props.enqueueSnackbar("Cant leave importnant fields empty",{variant:'warning'})
            return false
        }else{
            return true
        }
    }

    addSubCategory = async () => {
       if(this.validate()){

        let updatedSubCategory = new SubCategory(
            this.state.subCategory.subCategoryId,
            this.state.subCategory.subCategoryName,
            this.state.subCategory.subCategoryArabicName,
            this.state.subCategory.images,
            )

            try{
                if(this.state.addedImages.length!=0){
                this.setState({loading:{status:true,message:'Uploading images'}})
                let uploadedImages = await uploadImages(this.state.addedImages)
                updatedSubCategory['images']= await updatedSubCategory['images'].concat(uploadedImages)
                this.props.enqueueSnackbar("Images are uploaded successfully",{variant:"success"})
                }

                this.setState({loading:{status:true,message:'Updating subcategory'}})
                await FirebaseApp.auth().currentUser.getIdToken(true).then(async (idToken) => {
                        console.log('id',this.props.categoryId)
                        await addSubCategory(this.props.categoryId,updatedSubCategory,idToken)
                        this.props.enqueueSnackbar("Subcategory was added successfully",{variant:'success'})
                    })
                this.props.handleCloseWithRefresh()
            }catch(error){
                this.props.enqueueSnackbar("Add subcategory was failed "+error,{variant:'error'})
            }finally{
                this.setState({loading:{status:false,message:''}})
            }
         
        }
    }

    
    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'450px',position:'relative',paddingBottom:'20px'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Add SubCategory</h4>
                        <Card style={{padding:'10px'}}>
                        <div>
                                    <Field
                                        type='text'
                                        id='name'
                                        placeholder='Sub Category Name'
                                        size='small'
                                        value = {this.state.subCategory.subCategoryName}
                                        onChange = {(e)=>{this.setState({subCategory:{...this.state.subCategory, subCategoryName:e.target.value}})}}
                                        autoFocus={false}
                                        required={true}
                                    />
                                    <Field
                                        type='text'
                                        id='arabicName'
                                        placeholder='Arabic Name'
                                        value = {this.state.subCategory.subCategoryArabicName}
                                        onChange = {(e)=>{this.setState({subCategory:{...this.state.subCategory, subCategoryArabicName:e.target.value}})}}
                                        size='small'
                                        autoFocus={false}
                                        required={true}
                                    />
                                <div className="d-flex flex-direction-row" style={{width:'100%',marginBottom:'10px'}}>
                                    {this.state.addedImages.length==0?
                                    <div>
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon/>}
                                            size = "small"
                                            onClick = {(e) => {
                                                this.inputRef.current.click()
                                            }}
                                            color="primary"
                                            style={{marginLeft:'auto'}}
                                         >Add Image
                                        </Button>
                                        <input 
                                            hidden id="fileUpload" 
                                            type="file"  
                                            value={this.state.fileInput}
                                            ref = {this.inputRef} 
                                            accept="image/x-png,image/gif,image/jpeg" 
                                            onChange = {(e)=> {this.addImage(e)}} 
                                        /> 
                                    </div>
                                :null   
                                }
                                </div>
                                <div style={{ height:'230px',overflowY:'auto',overflowX:'hidden',width:'100%'}}>
                                    {this.state.addedImages.map((image, i) => {
                                        return (
                                            <Card style={{width:'380px',marginBottom:'10px'}}>
                                                <img 
                                                    style={{width:'300px',height:'100px',objectFit:'cover'}} 
                                                    src={image.url} 
                                                    onClick = {(e)=>this.openImageViewer(e)}
                                                    alt={''}/>
                                                <IconButton 
                                                    id={image.url}
                                                    aria-label="delete" 
                                                    style={{marginLeft:'15px'}}
                                                    value = {image.url}
                                                    onClick = {(e) => {this.deleteImage(e)}}
                                                    >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Card>
                                        )
                                    })}
                                    {this.state.addedImages.length==0?
                                        <div style={{width:'100%',height:'200px',display:'flex',flexDirection:'column'}}>
                                            <div style={{margin:'auto'}}>No images are added</div>
                                            </div>
                                        :null
                                    }
                                </div>
                            </div>
                        </Card>
                        <div className='d-flex flex-direction-row float-right' style={{marginTop:'10px'}}>
                        <Button
                                variant="contained"
                                startIcon={<CancelIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                style={{marginRight:'10px'}}
                                color="secondary"
                            >Cancel
                        </Button>

                        <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setState({confirm:{message:'Confirm',status:true}})
                                }}
                                color="primary"
                            >Add
                        </Button>
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
                    {this.state.confirm.status?
                    <   ConfirmationBox
                        open={this.state.confirm.status}
                        message = {this.state.confirm.message}
                        handleClose = {(e)=>{this.setState({confirm:{...this.state.confirm,status:false}})}}
                        handleCloseConfirmed = {
                            (e)=>{
                                this.setState({confirm:{...this.state.confirm,status:false}})
                                this.addSubCategory()
                            }
                        }
                    />
                    :null    
                    }
                    {this.state.imageViewer.status?
                            <ImageViewer
                            imgs={ this.state.imageViewer.images }
                            currImg={0}
                            isOpen = {this.state.imageViewer.status}
                            onClose={ this.closeImageViewer }
                            />
                            : null    
                    }
                    </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default withSnackbar(AddSubCategory)