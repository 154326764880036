import React, {Component} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'


//Icons
import FaceIcon from '@material-ui/icons/Face'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CategoryIcon from '@material-ui/icons/Category';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import SlideshowIcon from '@material-ui/icons/Slideshow'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import DescriptionIcon from '@material-ui/icons/Description';
import PaymentIcon from '@material-ui/icons/Payment';

import FirebaseApp from "../../FirebaseSetup"
import { AuthContext } from '../../context/auth/Context'

function ListItemLink(props){
    return <ListItem button component = {RouterLink} {...props} />
}



class Sidebar extends Component{

    static contextType = AuthContext

    checkUserRole =async () => {
        await FirebaseApp.auth().currentUser.getIdTokenResult()
      .then((idTokenResult) => {
         if (idTokenResult.claims.role =="admin") {
             alert(idTokenResult.claims.role)
           return true
         } else {
             alert("editor")
          return false
         }
      })
      .catch((error) => {
        console.log(error);
        return false
      })
      }

    render(){
        const {auth} = this.context
        return(
            <List>
                <ListItemLink to ="/">
                    <Tooltip title="Dashboard" placement="right" >
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Dashboard"/>
                </ListItemLink>

                <ListItemLink to = "/pendingOrders">
                    <Tooltip title="Pending Orders" placement="right" >
                        <ListItemIcon>
                            <FileCopyIcon/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Pending Orders"/>
                </ListItemLink>

                <ListItemLink to = "/completedOrders">
                    <Tooltip title="Completed Orders" placement="right" >
                        <ListItemIcon>
                            <AssignmentTurnedInIcon/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Completed Orders"/>
                </ListItemLink>
                <ListItemLink to = "/pendingPayments">
                    <Tooltip title="Pending Payments" placement="right" >
                        <ListItemIcon>
                            <PaymentIcon/>
                        </ListItemIcon>
                     </Tooltip>
                 <ListItemText primary="Pending Payments"/>
                </ListItemLink>

                <ListItemLink to = "/completedPayments">
                        <Tooltip title="Completed Payments" placement="right" >
                            <ListItemIcon>
                                <CheckCircleIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Completed Payments"/>
                </ListItemLink>

                

                <ListItemLink to = "/listOfProviders">
                        <Tooltip title="List of Providers" placement="right" >
                            <ListItemIcon>
                                <FaceIcon/>
                            </ListItemIcon>    
                        </Tooltip>
                    <ListItemText primary="List Of Providers"/>
                </ListItemLink>

                <ListItemLink to = "/manageCategories">
                    <Tooltip title="Manage Categories" placement="right" >
                        <ListItemIcon>
                            <CategoryIcon/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Manage Categories"/>
                </ListItemLink>

                <ListItemLink to = "/manageItems">
                        <Tooltip title="Manage Items" placement="right" >
                            <ListItemIcon>
                                <DescriptionIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Manage Items"/>
                </ListItemLink>
                <ListItemLink to = "/manageCustomers">
                        <Tooltip title="Manage Customers" placement="right" >
                            <ListItemIcon>
                                <PeopleAltIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Manage Customers"/>
                </ListItemLink> 
                <ListItemLink to = "/manageFeeds">
                        <Tooltip title="Manage Feeds" placement="right" >
                            <ListItemIcon>
                                <DynamicFeedIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Manage Feeds"/>
                </ListItemLink>

                <ListItemLink to = "/manageSlides">
                        <Tooltip title="Manage Slides" placement="right" >
                            <ListItemIcon>
                                <SlideshowIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Manage Slides"/>
                </ListItemLink>

                {
                    auth.currentUserRole =="admin"?
                    <ListItemLink to = "/settings">
                    <Tooltip title="Settings" placement="right" >
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Settings"/>
                    </ListItemLink>
                    : null
                }
                  

            </List>
        )
    }
}

export default Sidebar


/* <ListItemLink to = "/cancelledOrders">
                    <Tooltip title="Cancelled Orders" placement="right" >
                        <ListItemIcon>
                            <CancelIcon/>
                         </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Cancelled Orders"/>
                </ListItemLink>

                <ListItemLink to = "/pendingPayments">
                    <Tooltip title="Pending Payments" placement="right" >
                        <ListItemIcon>
                            <PaymentIcon/>
                        </ListItemIcon>
                     </Tooltip>
                 <ListItemText primary="Pending Payments"/>
                </ListItemLink>

                <ListItemLink to = "/completedPayments">
                        <Tooltip title="Completed Payments" placement="right" >
                            <ListItemIcon>
                                <CheckCircleIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Completed Payments"/>
                </ListItemLink>



<ListItemLink to = "/manageCustomers">
                        <Tooltip title="Manage Customers" placement="right" >
                            <ListItemIcon>
                                <PeopleAltIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Manage Customers"/>
                </ListItemLink> 
                
                                <ListItemLink to = "/manageFeeds">
                        <Tooltip title="Manage Feeds" placement="right" >
                            <ListItemIcon>
                                <DynamicFeedIcon/>
                            </ListItemIcon>
                        </Tooltip>
                    <ListItemText primary="Manage Feeds"/>
                </ListItemLink>
                
                */