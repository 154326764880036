import React, {Component} from 'react'
import {withSnackbar } from 'notistack'
import ImageViewer from 'react-images-viewer'
import moment from 'moment-timezone'
import {timeZone} from '../../../service/env'

import Field from '../../common/Field'
import {addFeeds, uploadImages, deleteImages} from '../../../repository/ConfigurationsRepository'
import _ from 'lodash'
import LoadingScreen from '../../common/LoadingScreen'
import ConfirmationBox from '../../common/ConfirmationBox'
import {FilterField, FilterDropDown,FilterDatePicker} from './../../common/FilterField'

//material-ui components
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'


//firebase
import FirebaseApp from '../../../FirebaseSetup'

class Update extends Component {

    constructor(props){
        super(props)
        this.state={
            feedId:this.props.feed.feedId,
            feedName:this.props.feed.feedName,
            feedType:this.props.feed.feedType,
            activeDays:this.props.feed.activeDays ,
            status:this.props.feed.status,
            images:this.props.feed.images,
            addedImages:[],
            deletedImages:[],
            loading:{
                status:false,
                message:''
            },
            fileInput:'',
            confirm:{
                status:false,
                message:''
            },
            imageViewer:{
                images:'',
                status:false
            }
        }
        this.inputRef = React.createRef()
    }

    openImageViewer = (e) => {
        let arr = []
        let img = {src:e.target.src}
        arr.push(img)
        this.setState({imageViewer:{images:arr,status:true}})
    }

    closeImageViewer = () => {
        this.setState({imageViewer:{images:[],status:false}})
    }

    addImage = (e) => {
        let imageFile = {
            url : URL.createObjectURL(e.target.files[0]),
            file : e.target.files[0]
        }
        let arr = this.state.addedImages.concat(imageFile)
        this.setState({ addedImages:arr})
    }

    deleteNewImage = (e) => {
        let arr =  this.state.addedImages.filter(file => e.currentTarget.value!=file.url)
        this.setState({addedImages:arr})
    }

    deleteOldImage = (e) => {
        let imageList = this.state.images
        var imagesToDelete = _.remove(imageList, (n) => {
           return n.id == e.currentTarget.value
        });
        let deletedImages = this.state.deletedImages
        deletedImages.concat(imagesToDelete)
        this.setState({deletedImages:deletedImages})
        this.setState({images:imageList})
        console.log('hello',imageList)
    }

    validate = () => {
        if(
            this.state.feedName=='' ||
            this.state.feedType =='' ||
            (this.state.images.length+this.state.addedImages.length==0)
        ){
            this.props.enqueueSnackbar("All fields are required!",{variant:'warning'})
            return false
        }else{
           
                return true
            
        }
    }

    updateFeed = async () => {
       if(this.validate()){

            let updatedFeed = {
                feedId: this.state.feedId,
                feedName: this.state.feedName,
                feedType: this.state.feedType,
                activeDays: new Date(this.state.activeDays).getTime(),
                status: this.state.status,
                images:this.state.images
            }

            console.log(updatedFeed)
            try{
                if(this.state.deletedImages.length!=0){
                    this.setState({loading:{status:true,message:'Deleting old images'}})
                    await deleteImages(this.state.deletedImages, 'feeds')
                }

                if(this.state.addedImages.length!=0){
                this.setState({loading:{status:true,message:'Uploading images'}})
                let uploadedImages = await uploadImages(this.state.addedImages, 'feeds')
                updatedFeed['images']= await updatedFeed['images'].concat(uploadedImages)
                console.log(uploadedImages)
                this.props.enqueueSnackbar("Images are uploaded successfully",{variant:"success"})
                }

                let feeds = this.props.feedList

                for(let x=0;x<feeds.feedList.length;x++){
                    if(feeds.feedList[x].feedId == updatedFeed.feedId){
                        feeds.feedList[x].feedName = updatedFeed.feedName
                        feeds.feedList[x].feedType = updatedFeed.feedType
                        feeds.feedList[x].activeDays = updatedFeed.activeDays
                        feeds.feedList[x].status = updatedFeed.status
                        feeds.feedList[x].images = updatedFeed.images
                    }
                }
                
                console.log(feeds)

                this.setState({loading:{status:true,message:'Updating feeds'}})
                await FirebaseApp.auth().currentUser.getIdToken(true).then(async (idToken) => {
                        await addFeeds(idToken,feeds)
                        this.props.enqueueSnackbar("Feeds were updated successfully",{variant:'success'})
                    })
                this.props.handleCloseWithRefresh()
            }catch(error){
                this.props.enqueueSnackbar("Update feeds was failed"+error,{variant:'error'})
            }finally{
                this.setState({loading:{status:false,message:''}})
            }
         
        }
    }

    
    render() {
        return (
            <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    disableBackdropClick={true}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                    <div style={{backgroundColor:'white',width:'850px',position:'relative',paddingBottom:'20px'}}
                    className="bg-light"
                    >
                    <div style={{margin:'20px'}}>
                        <h4>Update Feed</h4>
                        <br></br>
                        <div className='row'>
                            <div className='col-lg-6'>
                            
                                    <FormControl variant="outlined" style={{width:'180px'}}>
                                        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.status}
                                            onChange = {(e)=>{this.setState({status:e.target.value})}}
                                            autoWidth
                                            style={{fontSize:'12px'}}
                                            displayEmpty={true}
                                            defaultValue={this.props.defaultValue}
                                        >
                                            <MenuItem value="ACTIVE">Active</MenuItem>
                                            <MenuItem value="PAUSED">Paused</MenuItem>
                                        
                                            </Select>
                                    </FormControl>
                                    
                                <Field
                                    type='text'
                                    id='name'
                                    placeholder='Feed Name'
                                    size='small'
                                    value = {this.state.feedName}
                                    onChange = {(e)=>{this.setState({feedName:e.target.value})}}
                                    autoFocus={false}
                                    required={true}
                                />
                                <Field
                                    type='text'
                                    id='type'
                                    placeholder='Feed Type'
                                    size='small'
                                    value = {this.state.feedType}
                                    onChange = {(e)=>{this.setState({feedType:e.target.value})}}
                                    autoFocus={false}
                                />
                                <FilterDatePicker
                                id="to"
                                label="Valid till"
                                value={this.state.activeDays}
                                onChange={(date) => this.setState({activeDays:date})}
                            />
                                 
                            </div>
                            <div className='col-lg-6' style={{paddingTop:'15px'}}>
                            <div className="d-flex flex-direction-row" style={{width:'100%',marginBottom:'10px'}}>
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon/>}
                                    size = "small"
                                    onClick = {(e) => {
                                        this.inputRef.current.click()
                                    }}
                                    color="primary"
                                    style={{marginLeft:'auto'}}
                                >Add Image
                            </Button>
                            <input 
                                hidden id="fileUpload" 
                                type="file"  
                                value={this.state.fileInput}
                                ref = {this.inputRef} 
                                accept="image/x-png,image/gif,image/jpeg" 
                                onChange = {
                                    (e)=> { this.addImage(e)}
                                }  
                            />
                            </div>
                            <div style={{ height:'200px',overflowY:'auto',overflowX:'hidden',width:'100%'}}>
                                {this.state.addedImages.map((image, i) => {
                                    return (
                                        <Card style={{width:'360px',marginBottom:'10px'}}>
                                            <img 
                                                style={{width:'280px',height:'100px',objectFit:'cover'}} 
                                                src={image.url} 
                                                onClick = {(e)=>this.openImageViewer(e)}
                                                alt={''}/>
                                            <IconButton 
                                                id={image.url}
                                                aria-label="delete" 
                                                style={{marginLeft:'15px'}}
                                                value = {image.url}
                                                onClick = {(e) => {this.deleteNewImage(e)}}
                                                >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Card>
                                    )
                                })}
                                {this.state.images.map((image, i) => {
                                    return (
                                        <Card style={{width:'360px',marginBottom:'10px'}}>
                                            <img 
                                                style={{width:'280px',height:'100px',objectFit:'cover'}} 
                                                src={image.url} 
                                                onClick = {(e)=>this.openImageViewer(e)}
                                                alt={''}/>
                                            <IconButton 
                                                id={image.id}
                                                aria-label="delete" 
                                                style={{marginLeft:'15px'}}
                                                value = {image.id}
                                                onClick = {(e) => {this.deleteOldImage(e)}}
                                                >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Card>
                                    )
                                })}
                                {this.state.addedImages.length==0 && this.state.images.length==0?
                                     <div style={{width:'100%',height:'200px',display:'flex',flexDirection:'column'}}>
                                         <div style={{margin:'auto'}}>No images are added</div>
                                         </div>
                                    :null
                                }
                            </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='d-flex flex-direction-row float-right'>
                        <Button
                                variant="contained"
                                startIcon={<CancelIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.props.handleClose()
                                }}
                                style={{marginRight:'10px'}}
                                color="secondary"
                            >Cancel
                        </Button>

                        <Button
                                variant="contained"
                                startIcon={<CheckCircleIcon/>}
                                size = "small"
                                onClick = {(e) => {
                                    this.setState({confirm:{message:'Confirm',status:true}})
                                }}
                                color="primary"
                            >Update Feed
                        </Button>
                        </div>
                        </div>
                    {this.state.loading.status?
                    <   LoadingScreen
                        width='100%'
                        height='100%'
                        translateY ='200px'
                        message = {this.state.loading.message}
                    />
                    :null    
                    }
                    {this.state.confirm.status?
                    <   ConfirmationBox
                        open={this.state.confirm.status}
                        message = {this.state.confirm.message}
                        handleClose = {(e)=>{this.setState({confirm:{...this.state.confirm,status:false}})}}
                        handleCloseConfirmed = {
                            (e)=>{
                                this.setState({confirm:{...this.state.confirm,status:false}})
                                this.updateFeed()
                            }
                            
                        }
                    />
                    :null    
                    }
                    {this.state.imageViewer.status?
                            <ImageViewer
                            imgs={ this.state.imageViewer.images }
                            currImg={0}
                            isOpen = {this.state.imageViewer.status}
                            onClose={ this.closeImageViewer }
                            />
                            : null    
                    }
                    </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}
export default withSnackbar(Update)