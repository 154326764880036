import React, { Component} from 'react';
import {  Route, Redirect, withRouter } from 'react-router-dom'
import { AuthContext } from './context/auth/Context'
import './App.css';
import { SnackbarProvider} from 'notistack'

//importing pages
import Login from './component/pages/Login'

//firebase
import { userLogin, userLogout } from './context/auth/Actions';
import FirebaseApp from "./FirebaseSetup"
import AppLayout from './component/pages/AppLayout';



class App extends Component {
  static contextType = AuthContext

  checkUserRole =async () => {
    await FirebaseApp.auth().currentUser.getIdTokenResult()
  .then((idTokenResult) => {
     if (idTokenResult.claims.role =="editor") {
        //alert("editor")
     } else {
       //alert("Admin")
     }
  })
  .catch((error) => {
    console.log(error);
  });
  }

  componentDidMount() {

    const {auth,dispatch} = this.context
    FirebaseApp.auth().onAuthStateChanged(user => {
      if (user) {
          dispatch(userLogin(user))
          this.props.history.push('/')
      } else {
        dispatch(userLogout())
        this.props.history.push('/login')
      }
    })
  }

  render() {

    return (
      <SnackbarProvider maxSnack={5} anchorOrigin={{vertical: 'bottom',horizontal: 'right',}} autoHideDuration={2000}>
      <div className="App">
        {this.context.auth.currentUser ?
          <Route exact path="/" component={AppLayout} />
          :
          <div>
            <Redirect to={{ pathname: '/login' }} />
            <Route
              path="/login"
              exact={true}
              render={props => (
                <Login />
              )}
            />
          </div>
        }
      </div>
      </SnackbarProvider>
      )

    // return(
    //     <div className="App">
    //       <Router>
    //         <Route
    //           path="/"
    //           render = {props => {

    //             return (
    //               <div>

    //                 {this.context.auth.currentUser?

    //                 <AdminWrapper>
    //                     <Route
    //                       path="/pendingOrders"
    //                       exact={true}
    //                       render = {props => (
    //                         <PendingOrders/>
    //                       )}
    //                     />

    //                     <Route
    //                       path="/completedOrders"
    //                       exact={true}
    //                       render = {props => (
    //                         <CompletedOrders/>
    //                       )}
    //                     />

    //                     <Route
    //                       path="/cancelledOrders"
    //                       exact={true}
    //                       render = {props => (
    //                         <CancelledOrders/>
    //                       )}
    //                     />
    //                     <Route
    //                       path="/"
    //                       exact={true}
    //                       render = {props => (
    //                         <Dashboard/>
    //                       )}
    //                     />

    //                 </AdminWrapper> 
    //                 :

    //                 <Redirect to={{pathname:'/login'}}/>
    //               }

    //               {this.context.auth.currentUser==null?
    //                 <Route
    //                 path="/login"
    //                 exact={true}
    //                 render = {props => (
    //                   <Login/>
    //                 )}
    //               />
    //             :
    //            <Redirect to={{pathname:'/'}}/>
    //             }

    //               </div>
    //             )
    //           }}/>
    //       </Router>
    //     </div>

    // )
  }
}

export default withRouter(App)
