import React, {Component} from 'react'

import TextField from '@material-ui/core/TextField'

class Field extends Component {

    render() {
        return (
            <TextField
                 variant="outlined"
                 margin="normal"
                 disabled={this.props.disabled}
                 required={this.props.required}
                 className = {this.props.textField}
                 type={this.props.type}
                 id={this.props.name}
                 label={this.props.placeholder}
                 name={this.props.name}
                 autoComplete={this.props.type}
                 onChange = {this.props.onChange}
                 onBlur = {this.props.onBlur}
                 autoFocus = {this.props.autoFocus}
                 style={{width:'100%'}}
                 size = {this.props.size}
                 value = {this.props.value}
                 multiline = {this.props.multiline}
                 rows = {this.props.rows}
             />
        )
    }
}


export default Field