
const AuthReducer = (state, action) => {
    switch (action.type){
        case 'USER_LOGIN':
            //console.log("Action",action)
            return {
                ...state,
                currentUser: action.currentUser
                
            }
        case 'USER_LOGOUT':
            return {
                ...state,
                currentUser:null
            }
        case 'SET_NOTIFICATIONS':
            return {
                ...state,
                notifications:action.notifications
            }
        case 'SET_USER_ROLE':
            return{
                ...state,
                currentUserRole: action.role
            }
        case 'CLEAR_USER_ROLE':
            return{
                ...state,
                currentUserRole: ''
            }
        default:
            return(
                state
            )
    }
}

export default AuthReducer;